import { Grid, styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'

export const Button = styled(LoadingButton)(({ theme }) => ({
  width: 180,
  '&:last-child': {
    marginLeft: theme.spacing(1.5),
  },
}))

export const Header = styled(Grid)({})
