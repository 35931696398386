import { styled, Dialog as BaseDialog } from '@mui/material'

export const Dialog = styled(BaseDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('sm')]: {
      height: '90vh',
      width: theme.breakpoints.values['md'],
    },
  },
}))
