import { env, firebaseConfig } from 'config'
import { initializeApp, FirebaseApp } from '@firebase/app'
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@firebase/app-check'

class Firebase {
  private static app: FirebaseApp

  private static appCheck: AppCheck | null

  static getInstance() {
    if (!this.app) {
      try {
        const app = initializeApp(firebaseConfig)
        this.app = app
      } catch (e) {
        console.error(e)
      }
      if (
        env.firebaseAppCheckDebug !== null &&
        env.firebaseAppCheckSiteKey !== null
      ) {
        try {
          if (env.firebaseAppCheckDebug === 'true') {
            ;(window as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
              env.firebaseAppCheckSiteKey
          }
          const appCheck = initializeAppCheck(this.app, {
            provider: new ReCaptchaEnterpriseProvider(
              env.firebaseAppCheckSiteKey
            ),
            isTokenAutoRefreshEnabled: true,
          })

          this.appCheck = appCheck
        } catch (e) {
          console.error(e)
        }
      } else {
        this.appCheck = null
      }
    }
    return { app: this.app, appCheck: this.appCheck }
  }
}

export default Firebase
