import { ReactNode } from 'react'
import {
  Button,
  DialogProps,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

export interface ConfirmDialogProps extends DialogProps {
  onConfirm(): void
  onCancel(): void
  isLoading?: boolean
  message: string | ReactNode
}

const ConfirmDialog = ({
  onConfirm,
  onCancel,
  children,
  message,
  isLoading = false,
  ...props
}: ConfirmDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog fullWidth={false} {...props}>
      <DialogContent>
        <Typography variant="h6" textAlign="center">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          color="primary"
          variant="outlined"
          disabled={isLoading}
        >
          {t('no')}
        </Button>
        <LoadingButton
          onClick={onConfirm}
          loading={isLoading}
          color="primary"
          variant="contained"
        >
          {t('yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
