import { createTheme, ThemeOptions } from '@mui/material'
import palette from './palette'
import components from './components'

const theme: ThemeOptions = {
  components,
  palette,
  shape: {
    borderRadius: 0,
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
}

export default createTheme(theme)
