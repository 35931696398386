import {
  useFormContext,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import {
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from '@mui/material'
import { Option } from 'shared/types'

export type SelectProps = Partial<BaseSelectProps> & {
  name: UseControllerProps['name']
  defaultValue?: string
  options: Option[]
}

const TextField = ({
  name,
  label,
  options = [],
  defaultValue = '',
  ...props
}: SelectProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <BaseSelect
        label={label}
        inputRef={ref}
        labelId={`${name}-label`}
        {...inputProps}
        {...props}
      >
        {options.map(({ title, value }) => (
          <MenuItem key={`menu-item-${title}-${value}`} value={value}>
            {title}
          </MenuItem>
        ))}
      </BaseSelect>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default TextField
