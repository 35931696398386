import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react'
import { TextField, styled, InputAdornment, Grid } from '@mui/material'
import { GridToolbarContainer as BaseGridToolbarContainer } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Search } from '@mui/icons-material'
import { Search as GWSearch } from 'shared/assets'
import { isGlobalworth } from 'config'

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-positionStart': {
    marginBottom: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    paddingTop: theme.spacing(1),
  },
}))

const GWSearchIcon = styled(GWSearch)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

const GridToolbarContainer = styled(BaseGridToolbarContainer)(({ theme }) => ({
  ...(isGlobalworth && {
    padding: theme.spacing(0.5),
  }),
}))

interface DataGridToolbarProps {
  searchQuery: string
  onSearch: (searchQuery: string) => void
  isLoading: boolean
}

const DataGridToolbar = ({
  searchQuery,
  onSearch,
  isLoading,
}: DataGridToolbarProps) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState(searchQuery)

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => setQuery(e.target.value)

  const handleSearch: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key !== 'Enter') return
    onSearch(query)
  }

  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item xs={12}>
          <StyledTextField
            variant={isGlobalworth ? 'standard' : 'filled'}
            value={query}
            onChange={handleChange}
            onKeyPress={handleSearch}
            placeholder={t('searchBar')}
            disabled={isLoading}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position={isGlobalworth ? 'end' : 'start'}>
                  {isGlobalworth ? <GWSearchIcon /> : <Search />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  )
}

export default DataGridToolbar
