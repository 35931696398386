import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import env from './env'
import { FirebaseAuth } from 'shared/services'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = createUploadLink({
  uri: `${env.apiUrl}/graphql`,
  credentials: 'same-origin',
})

export const getDataClient = (auth: FirebaseAuth) => {
  const authLink = setContext(async (_, { headers }) => {
    let authToken = ''
    try {
      const authTokenResult = await auth.getIdToken()
      if (authTokenResult) {
        authToken = authTokenResult
      }
    } catch (e) {
      console.error(e)
    }
    let appCheckToken = ''
    try {
      const result = await auth.getAppCheckToken()
      if (result) {
        appCheckToken = result.token
      }
    } catch (e) {
      console.error(e)
    }

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
        'X-App-Identifier': env.appIdentifier,
        ...(appCheckToken && { 'X-AppCheck-Token': appCheckToken }),
        'Apollo-Require-Preflight': 'true',
      },
    }
  })
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
  return client
}
