import { createContext, useContext, useEffect, useState } from 'react'

type RemoteConfigType = { [k: string]: string } | undefined

type Auth = {
  getRemoteConfigValues: () => Promise<RemoteConfigType>
}

export const RemoteConfigContext = createContext<RemoteConfigType | null>(null)

export const RemoteConfigProvider: React.FC<{ auth: Auth }> = ({
  auth,
  children,
}) => {
  const [fetchedData, setFetchedData] = useState<RemoteConfigType>()

  const fetchRemoteConfig = async () => {
    try {
      const remoteConfigValues = await auth.getRemoteConfigValues()
      setFetchedData(remoteConfigValues)
    } catch {
      console.error()
    }
  }

  useEffect(() => {
    fetchRemoteConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <RemoteConfigContext.Provider value={fetchedData}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

export const useRemoteConfigContext = () => useContext(RemoteConfigContext)
