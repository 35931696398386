import { useTranslation } from 'react-i18next'
import { Option, ExtendedOption, AccessProfile } from 'shared/types'

export const useMapProfileToOption = () => {
  const { t } = useTranslation()
  const mapProfileToOption = ({
    name: title,
    id: value,
  }: AccessProfile): Option<string, string> => {
    if (!title)
      return {
        title: t('anonymousProfile'),
        value,
      }
    return {
      title: title || '',
      value,
    }
  }

  return mapProfileToOption
}

interface ProfileWithDetails extends AccessProfile {
  disabled?: boolean
}

export const useMapProfileToExtendedOption = () => {
  const { t } = useTranslation()
  const mapProfileToOption = ({
    name: title,
    id: value,
    disabled,
  }: ProfileWithDetails): ExtendedOption<string, string> => {
    if (!title)
      return {
        title: t('anonymousUser'),
        value,
      }
    return {
      title: title || '',
      value,
      disabled,
    }
  }

  return mapProfileToOption
}
