import {
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Dialog } from './EditListDialog.style'
import { Close } from 'shared/assets'

interface EditListDialogProps {
  open: boolean
  onClose: () => void
  children: JSX.Element
}

const EditListDialog = ({ open, onClose, children }: EditListDialogProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle textAlign="end">
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default EditListDialog
