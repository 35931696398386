import { styled } from '@mui/material'

export const Container = styled('div')<{ fullHeight?: boolean }>(
  ({ theme, fullHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: fullHeight ? '100vh' : '100%',
    width: '100%',
    '& > .MuiCircularProgress-root': {
      marginBottom: theme.spacing(2),
    },
  })
)
