import {
  styled,
  Typography,
  Button as BaseButton,
  AppBar as BaseAppBar,
  Avatar as BaseAvatar,
  IconButton as BaseIconButton,
} from '@mui/material'

export const StyledTopbar = styled(BaseAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  borderRadius: 0,
}))

export const Avatar = styled(BaseAvatar)(({ theme }) => ({
  marginLeft: theme.spacing(),
}))

export const IconButton = styled(BaseIconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))

export const ButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
})

export const ButtonText = styled(Typography)({
  textTransform: 'none',
})

export const Button = styled(BaseButton)(({ theme }) => ({
  '& svg': {
    fontSize: 36,
  },
  color: theme.palette.common.white,
}))
