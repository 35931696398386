import { HTMLProps, PropsWithChildren } from 'react'
import { FormProvider, FormProviderProps, SubmitHandler } from 'react-hook-form'

export type FormProps<T = unknown> = Omit<
  FormProviderProps<T> &
    Omit<HTMLProps<HTMLFormElement>, 'onSubmit'> & {
      onSubmit: SubmitHandler<T>
    },
  'children'
> & { formProps?: Partial<HTMLProps<HTMLFormElement>> }

const Form = ({
  children,
  onSubmit,
  style,
  className,
  formProps,
  ...props
}: PropsWithChildren<FormProps<any>>) => (
  <form
    onSubmit={props.handleSubmit(onSubmit)}
    className={className}
    {...formProps}
  >
    <FormProvider {...props}>{children}</FormProvider>
  </form>
)

export default Form
