import { useMutation } from '@apollo/client'
import { CONFIRM_RESOURCE_BOOKING_WITH_CODE } from './mutations'
import { ConfirmResourceBookingWithCodeVariables } from './types'

export const useConfirmBookingWithCode = () => {
  const confirmBooking = useMutation<
    any,
    ConfirmResourceBookingWithCodeVariables
  >(CONFIRM_RESOURCE_BOOKING_WITH_CODE, {
    onError: () => {},
  })

  return confirmBooking
}
