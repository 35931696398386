import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query CurrentUserQuery {
    viewer {
      isAdmin,
      name,
      id,
      email,
      viewerPreferences {
        marketingConsentAccepted
      }
    }
  }
`

export const GET_CURRENT_USER_ACCESS_DETAILS = gql`
  query CurrentUserAccessDataQuery {
    viewer {
      id
      isAdmin
      organizations {
        totalCount
      }
    }
  }
`
