import { Grid, ListItemButton, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { StaticDatePicker } from '@mui/lab'
import { dateToFormattedString } from 'shared/utils'
import {
  DateTimePickerWrapper,
  ListItemText,
  TimeList,
} from './StaticDateTimePicker.style'
import { useTimeFieldOptions } from 'shared/hooks'

interface StaticDateTimePickerProps {
  selectedDate: Date | null
  onDateChange: (date: Date | null) => any
}

const DATE_FORMAT_PATTERN = 'dd.MM.yyyy'

const createDateTime = (date: Date, time: Date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes()
  )
}

const StaticDateTimePicker = ({
  selectedDate,
  onDateChange,
}: StaticDateTimePickerProps) => {
  const [date, setDate] = useState<Date | null>(null)
  const [time, setTime] = useState<Date | null>(null)
  const [formattedDate, setFormattedDate] = useState<string | null>(null)

  useEffect(() => {
    selectedDate
      ? setFormattedDate(dateToFormattedString(selectedDate))
      : setFormattedDate('——')
  }, [selectedDate])

  const handleDateChange = (dateInput: Date | null) => {
    if (!dateInput) return
    const defaultTime = new Date(timeOptions[0].value)
    const dateTime = time
      ? createDateTime(dateInput, time)
      : createDateTime(dateInput, defaultTime)
    setDate(dateInput)
    if (!selectedDate || !time) {
      setTime(defaultTime)
    }
    onDateChange(dateTime)
  }

  const handleTimeChange = (timeInput: Date) => {
    const dateTime = date ? createDateTime(date, timeInput) : timeInput
    setTime(timeInput)
    onDateChange(dateTime)
  }

  const timeOptions = useTimeFieldOptions({
    timeFormat: 'HH:mm',
    date: selectedDate,
  })

  return (
    <Grid container flexDirection="column">
      {formattedDate && (
        <Grid item paddingLeft={3}>
          <Typography variant="h5">{`${formattedDate}`}</Typography>
        </Grid>
      )}
      <DateTimePickerWrapper container>
        <StaticDatePicker
          showToolbar={false}
          inputFormat={DATE_FORMAT_PATTERN}
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={params => <TextField {...params} />}
        />
        <TimeList>
          {timeOptions.map(option => {
            const isLastOption = option === timeOptions[timeOptions.length - 1]
            const isSelected = !!(
              selectedDate &&
              dateToFormattedString(selectedDate, 'HH:mm') === option.title
            )

            const handleTimeClick = () => {
              if (!isLastOption) {
                handleTimeChange(new Date(option.value))
              }
            }

            return (
              <ListItemButton
                key={option.value}
                disabled={isLastOption}
                onClick={handleTimeClick}
                selected={isSelected}
                disableRipple
              >
                <ListItemText primary={option.title} />
              </ListItemButton>
            )
          })}
        </TimeList>
      </DateTimePickerWrapper>
    </Grid>
  )
}

export default StaticDateTimePicker
