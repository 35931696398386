import { useState } from 'react'
import { List, Collapse } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { SvgIconComponent as CustomSvgIconComponent } from 'shared/types'
import { ModuleRoute } from 'shared/types'
import { DrawerSubItemLink } from './SubrouteList.style'
import DrawerItem from './DrawerItem'

type ModuleSubroutes = Required<ModuleRoute>['subroutes']

export interface SubrouteListProps {
  data: ModuleSubroutes
  label?: string
  icon?: SvgIconComponent | CustomSvgIconComponent
}

const SubrouteList = ({ data, label, icon }: SubrouteListProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <DrawerItem
        icon={icon}
        label={label}
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      />
      <Collapse in={expanded}>
        <List disablePadding>
          {data.map(({ path, linkSettings = {} }, i) => (
            <DrawerSubItemLink
              key={`nav-item-${i}`}
              path={path}
              {...linkSettings}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default SubrouteList
