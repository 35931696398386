import { Option } from 'shared/types'
import { SelectChangeEvent } from '@mui/material'
import {
  Icon,
  MenuItem,
  Checkbox,
  Select,
  ListItemText,
  Input,
} from './SelectFilter.style'

interface SelectFilterProps {
  value: string[]
  onChange: (event: SelectChangeEvent<string[]>) => void
  label: string
  options: Option<string, string>[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SelectFilter = ({
  value,
  onChange,
  options,
  label,
}: SelectFilterProps) => {
  return (
    <Select
      multiple
      value={value}
      input={<Input />}
      startAdornment={<Icon />}
      onChange={(event: unknown) =>
        onChange(event as SelectChangeEvent<string[]>)
      }
      renderValue={() =>
        `${label} ${value.length > 0 ? `(${value.length})` : ''}`
      }
      displayEmpty
    >
      {options.map(({ title, value: optionValue }) => (
        <MenuItem key={`filter-item-${optionValue}`} value={optionValue}>
          <Checkbox checked={value.indexOf(optionValue) > -1} />
          <ListItemText primary={title} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectFilter
