import { Popover } from '@mui/material'
import {
  Icon,
  FilterTitle,
  ButtonBase,
  TabsWrapper,
  Tab,
  Tabs,
} from './DateTimeRangePicker.style'
import { useTranslation } from 'react-i18next'
import { usePopover } from 'shared/hooks'
import { SyntheticEvent, useState } from 'react'
import { dateToFormattedString } from 'shared/utils'
import DateTimeFilter from './DateTimeFilter'
import useDateRange from './DateTimeRangePicker.utils'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`datetime-tab-panel-${index}`}
      aria-labelledby={`datetime-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

interface DateRangeProps {
  fromDate: Date | null
  toDate: Date | null
  onFromDateChange: (date: Date | null) => any
  onToDateChange: (date: Date | null) => any
  title: string
}

const DateTimeRangePicker = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  title,
}: DateRangeProps) => {
  const { t } = useTranslation()

  const [tab, setTab] = useState(0)

  const [anchorEl, isPopoverOpen, openPopover, closePopover] = usePopover()

  const {
    currentFromDate,
    currentToDate,
    handleClearDates,
    resetPickers,
    handleFromDateChange,
    handleToDateChange,
  } = useDateRange({ fromDate, toDate })

  const handleConfirm = () => {
    onFromDateChange(currentFromDate)
    onToDateChange(currentToDate)
  }

  const handlePopoverClose = () => {
    resetPickers()
    closePopover()
    setTab(0)
  }

  const TAB_ITEMS = [
    {
      label: 'startDate',
      date: currentFromDate,
      component: (
        <DateTimeFilter
          clearDateLabel={t('clearDates')}
          onClearDate={handleClearDates}
          onConfirm={handleConfirm}
          onDateChange={date => handleFromDateChange(date)}
          selectedDate={currentFromDate}
        />
      ),
    },
    {
      label: 'endDate',
      date: currentToDate,
      component: (
        <DateTimeFilter
          clearDateLabel={t('clearDates')}
          onClearDate={handleClearDates}
          onConfirm={handleConfirm}
          onDateChange={date => handleToDateChange(date)}
          selectedDate={currentToDate}
        />
      ),
    },
  ]

  const handleTabChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setTab(newValue)
  }

  return (
    <>
      <ButtonBase onClick={openPopover}>
        <Icon />
        <FilterTitle>{`${t(title)} ${
          fromDate || toDate ? '●' : ''
        }`}</FilterTitle>
      </ButtonBase>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TabsWrapper container>
          <Tabs value={tab} onChange={handleTabChange}>
            {TAB_ITEMS.map(({ label, date }) => (
              <Tab
                key={`datetime-tab-item-${label}`}
                label={`${t(label)}: ${
                  date ? `${dateToFormattedString(date)}` : ''
                }`}
              />
            ))}
          </Tabs>
          {TAB_ITEMS.map(({ label, component }, idx) => {
            return (
              <TabPanel
                key={`datetime-tab-panel-${label}`}
                index={idx}
                value={tab}
              >
                {component}
              </TabPanel>
            )
          })}
        </TabsWrapper>
      </Popover>
    </>
  )
}

export default DateTimeRangePicker
