import env from './env'
import * as themes from './theme'

export * from './constants'
export * from './enums'
export { default as env } from './env'
export { default as i18n } from './i18n'
export { default as paths } from './paths'
export * from './requestConfig'
export * from './dataClient'
export { default as firebaseConfig } from './firebase'

const selectedTheme = Object.entries(themes).find(
  ([key, value]) => key === env.productFlavor
)

export const theme = selectedTheme
  ? selectedTheme[1].default
  : themes.bluebolt.default

export const isBlueBolt = env.productFlavor === 'bluebolt'
export const isGlobalworth = env.productFlavor === 'globalworth'
