import { CircularProgress } from '@mui/material'
import { ExternalSystemField } from 'shared/types'
import ExternalSystemFieldRow from './ExternalSystemFieldRow'
import {
  LoaderWrapper,
  ListWrapper,
  SelectedListWrapper,
  BasicListWrapper,
} from './ExternalSystemFieldsList.style'

interface ExternalSystemFieldsListProps {
  options: ExternalSystemField[]
  selectedOptions?: string[]
  onSave: (id: string, value: string) => void
  loading?: boolean
}

const ExternalSystemFieldsList = ({
  options,
  selectedOptions: selectedOptionIds,
  onSave,
  loading,
}: ExternalSystemFieldsListProps) => {
  const selectedOptions =
    selectedOptionIds && selectedOptionIds.length
      ? options.filter(({ node: { id } }) => selectedOptionIds.includes(id))
      : []
  const notSelectedOptions =
    selectedOptionIds && selectedOptionIds.length
      ? options.filter(({ node: { id } }) => !selectedOptionIds.includes(id))
      : []

  return loading ? (
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>
  ) : !selectedOptionIds || !selectedOptionIds.length ? (
    <BasicListWrapper>
      {options.map(option => (
        <ExternalSystemFieldRow
          {...option}
          onSave={onSave}
          key={option.node.id}
        />
      ))}
    </BasicListWrapper>
  ) : (
    <>
      <SelectedListWrapper>
        <ListWrapper>
          {selectedOptions.map(option => (
            <ExternalSystemFieldRow
              {...option}
              onSave={onSave}
              key={option.node.id}
            />
          ))}
        </ListWrapper>
      </SelectedListWrapper>
      <ListWrapper>
        {notSelectedOptions.map(option => (
          <ExternalSystemFieldRow
            {...option}
            onSave={onSave}
            key={option.node.id}
          />
        ))}
      </ListWrapper>
    </>
  )
}

export default ExternalSystemFieldsList
