import { styled, Box } from '@mui/material'
import { isGlobalworth } from 'config'

export const LoaderWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const SelectedListWrapper = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.palette.primary.main}`,
  borderRadius: isGlobalworth ? '0px' : '10px',
  margin: theme.spacing(2, 0, 0),
}))

export const ListWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

export const BasicListWrapper = styled(Box)({
  width: '100%',
})
