import { useSnackbar as baseUseSnackbar, OptionsObject } from 'notistack'

export const useNotificationSnackbar = () => {
  const { enqueueSnackbar } = baseUseSnackbar()

  const options: OptionsObject = {
    variant: 'success',
    autoHideDuration: 3000,
  }

  const showSnackbar = (message: string) => enqueueSnackbar(message, options)

  return [showSnackbar]
}
