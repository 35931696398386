import { PaletteOptions } from '@mui/material'
import createPalette from '@mui/material/styles/createPalette'

const palette: PaletteOptions = {
  primary: {
    light: '#688EE8',
    main: '#307FF5',
    dark: '#1976D2',
    contrastText: '#FBFCFF',
  },
  secondary: {
    main: '#E9F5FE',
  },
  text: {
    primary: '#121212',
  },
  info: {
    main: '#C0DEFF',
    light: '#D2D7E1',
    dark: '#949494',
  },
  grey: {
    100: '#EDEDED',
    300: '#B3B3B3',
    400: '#c6c6c6',
    500: '#c4c4c4',
    600: '#717783',
  },
  common: {
    white: '#FBFCFF',
  },
  action: {
    hover: '#F1F3FF',
  },
}

export default createPalette(palette)
