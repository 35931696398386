import { useEffect, useState, useCallback, useRef } from 'react'

export interface PaginationProps {
  page: number
  pageCount: number
  onPage: number
  changePageSize: (onPage: number) => void
  goNextPage: () => void
  goPrevPage: () => void
  goFirstPage: () => void
  startCursor: string | undefined
  endCursor: string | undefined
  canGoNextPage: boolean
  canGoPrevPage: boolean
  initPageSize: number
  pageSizeOptions: number[]
}

export interface SearchProps {
  query: string
  setQuery: (query: string) => any
}

interface Variables {
  query?: string
  first?: number
  last?: number
  after?: string
  before?: string
}

interface Props {
  initPageSize?: number
  pageInfo:
    | {
        endCursor: string
        hasNextPage: boolean
        startCursor: string
        hasPreviousPage: boolean
      }
    | undefined
  loading: boolean
  totalCount: number
  refetch: (variables: Variables) => void
}

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]

export const usePagination = ({
  initPageSize = PAGE_SIZE_OPTIONS[0],
  pageInfo,
  loading,
  totalCount,
  refetch,
}: Props) => {
  const [query, setQuery] = useState('')

  const [onPage, setOnPage] = useState(initPageSize)

  const [page, setPage] = useState(1)

  const [totalCountState, setTotalCountState] = useState(totalCount)

  useEffect(() => {
    if (totalCount !== totalCountState && !loading) {
      setTotalCountState(totalCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const handleQueryChange = useCallback(
    (query: string) => {
      setPage(1)
      refetch({
        query: query,
        first: onPage,
        after: undefined,
        last: undefined,
        before: undefined,
      })
    },
    [onPage, refetch]
  )

  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      const timeOutId = setTimeout(() => handleQueryChange(query), 400)
      return () => clearTimeout(timeOutId)
    } else {
      didMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const pageCount = Math.ceil(totalCountState / onPage)

  const startCursor = pageInfo?.startCursor

  const endCursor = pageInfo?.endCursor

  const goFirstPage = useCallback(() => {
    setPage(1)
    refetch({
      query: query ? query : undefined,
      first: onPage,
      after: undefined,
      last: undefined,
      before: undefined,
    })
  }, [onPage, refetch, query])

  const goNextPage = useCallback(() => {
    if (endCursor) {
      setPage(page => page + 1)
      refetch({
        query: query ? query : undefined,
        first: onPage,
        after: endCursor,
        last: undefined,
        before: undefined,
      })
    } else {
      goFirstPage()
    }
  }, [onPage, endCursor, refetch, query, goFirstPage])

  const goPrevPage = useCallback(() => {
    if (startCursor) {
      setPage(page => page - 1)
      refetch({
        query: query ? query : undefined,
        first: undefined,
        after: undefined,
        last: onPage,
        before: startCursor,
      })
    } else {
      goFirstPage()
    }
  }, [onPage, startCursor, refetch, query, goFirstPage])

  const changePageSize = useCallback(
    (onPage: number) => {
      setOnPage(onPage)
      setPage(1)
      refetch({
        query: query ? query : undefined,
        first: onPage,
        after: undefined,
        last: undefined,
        before: undefined,
      })
    },
    [refetch, query]
  )

  const canGoNextPage = pageCount > page && !loading

  const canGoPrevPage = page > 1 && !loading

  const resetPage = () => setPage(1)

  const pagination: PaginationProps = {
    page,
    pageCount,
    onPage,
    changePageSize,
    goNextPage,
    goPrevPage,
    goFirstPage,
    startCursor,
    endCursor,
    canGoNextPage,
    canGoPrevPage,
    initPageSize,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  }

  const search: SearchProps = {
    query,
    setQuery,
  }

  return {
    pagination,
    resetPage,
    search,
  }
}
