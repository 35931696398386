import {
  Box,
  Grid,
  styled,
  ListItemText as BaseListItemText,
} from '@mui/material'

export const DateTimePickerWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxHeight: 300,
  },
}))

export const ListItemText = styled(BaseListItemText)({
  display: 'flex',
  justifyContent: 'center',
})

export const TimeList = styled(Box)(({ theme }) => ({
  maxHeight: 280,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  minWidth: 200,
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    minWidth: 'auto',
    width: '100%',
    flex: 'auto',
  },
}))
