import { PinDropOutlined } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { Zones } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.zones,
    admin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Zones : PinDropOutlined,
      label: 'nav.zones',
    },
    component: Loadable({
      component: () => import('./pages/Zones'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.addZone,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddZone'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.zonesList',
          href: paths.zones,
        },
      },
    },
  },
  {
    path: paths.zone,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Zone'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.zonesList',
          href: paths.zones,
        },
      },
    },
  },
]

export default routes
