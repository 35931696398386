import { useTranslation } from 'react-i18next'
import {
  NestedMultiselectListOption,
  NestedMultiselectListSelectedValue,
} from 'shared/components'

export const mapAccessToLockListSelectedValue = ({
  node: { id },
  options: { nodes },
}: {
  node: { id: string }
  options: { nodes: { id: string }[] }
}): NestedMultiselectListSelectedValue<string> => ({
  value: id,
  subItems: nodes.map(node => node.id),
})

export const useMapAccessToLockListOption = () => {
  const { t } = useTranslation()

  return ({
    node: { id, name, description },
    options,
  }: {
    node: { id: string; name: string; description?: string }
    options: {
      nodes: { id: string; title: string; displayValue: string | null }[]
    }
  }): NestedMultiselectListOption<string, string> => ({
    title: name,
    value: id,
    subtitle: description,
    subItems: options.nodes.map(({ title, id, displayValue }) => {
      const parsedTitle = t(`enums.lockOptions.${title}`)
      return {
        title: displayValue ? `${parsedTitle}: ${displayValue}` : parsedTitle,
        value: id,
      }
    }),
  })
}
