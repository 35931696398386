import { DialogProps, Link as MaterialLink, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MODULE_NAME } from 'modules/userPreferences/strings'
import { useConsents } from 'shared/hooks'
import ConfirmDialog from './ConfirmDialog'
import { Link as RouterLink } from 'react-router-dom'

export interface MarketingConsentDialogProps extends DialogProps {
  onCancel(): void
}

const MarketingConsentDialog = ({
  onCancel,
  children,
  ...props
}: MarketingConsentDialogProps) => {
  const { t } = useTranslation(MODULE_NAME)
  const { setMarketingConsentChoice, loading, privacyPolicyLink } =
    useConsents()

  const onMarketingConsentChoice = async (accepted: boolean) => {
    await setMarketingConsentChoice(accepted)
    onCancel()
  }

  return (
    <ConfirmDialog
      message={
        <>
          <Typography sx={{ pb: 2 }} variant="h4" display="block">
            {t('marketing.alert.title')}
          </Typography>
          <Typography sx={{ pb: 1 }} display="block">
            {t('marketing.alert.message.start')}
          </Typography>
          <MaterialLink
            component={RouterLink}
            to={{ pathname: privacyPolicyLink }}
            target="_blank"
          >
            {t('marketing.alert.message.privacyPolicy')}
          </MaterialLink>
          <Typography sx={{ pt: 1 }} display="block">
            {t('marketing.alert.message.end')}
          </Typography>
        </>
      }
      onCancel={() => onMarketingConsentChoice(false)}
      open={props.open}
      onConfirm={() => onMarketingConsentChoice(true)}
      isLoading={loading}
    />
  )
}

export default MarketingConsentDialog
