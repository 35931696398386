import { Grid, styled } from '@mui/material'

export const Wrapper = styled(Grid)(({ theme }) => ({
  flex: '1 1 auto',
  height: 0,
  overflow: 'auto',
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))
