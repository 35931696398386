import { PersonOutlineOutlined } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { Users } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.users,
    admin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Users : PersonOutlineOutlined,
      label: 'nav.users',
    },
    component: Loadable({
      component: () => import('./pages/Users'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.addUser,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddUsers'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.guestList',
          href: paths.users,
        },
      },
    },
  },
  {
    path: paths.user,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/User'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.guestList',
          href: paths.users,
        },
      },
    },
  },
]

export default routes
