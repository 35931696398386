import { CircularProgress } from '@material-ui/core'
import { styled } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  height: '100%',
  zIndex: 999,
  alignItems: 'center',
  backgroundColor: theme.palette.action.hover,
}))

const DataGridOverlay = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

export default DataGridOverlay
