import {
  Typography,
  ButtonBase as BaseButtonBase,
  Button as BaseButton,
  styled,
  Box,
  Grid,
  Tabs as BaseTabs,
  Tab as BaseTab,
} from '@mui/material'
import { isGlobalworth } from 'config'
import { Filter } from 'shared/assets'

export const Icon = styled(Filter)(({ theme }) => ({
  stroke: 'inherit',
  marginRight: theme.spacing(0.75),
  width: 11,
  height: 11,
  transform: 'translateY(-10%)',
  color: theme.palette.primary.main,
}))

export const ButtonBase = styled(BaseButtonBase)(({ theme }) => ({
  padding: 6,
}))

export const Button = styled(BaseButton)(({ theme }) => ({
  fontWeight: 400,
  textTransform: 'none',
  borderRadius: isGlobalworth ? 0 : 10,
}))

export const FilterTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 12,
  lineHeight: '14px',
}))

export const TabsWrapper = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    width: '600px',
  },
}))

export const Tabs = styled(BaseTabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const Tab = styled(BaseTab)({
  width: '50%',
})

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(1, 2, 2, 3),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'space-between',
  },
}))
