import {
  styled,
  Button as BaseButton,
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
  alpha,
  Box,
} from '@mui/material'

export const Button = styled(BaseButton)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.down('sm')]: {
    '& .MuiButton-endIcon': { marginLeft: 0 },
  },
}))

export const Menu = styled(BaseMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 170,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
  '& .Mui-disabled': {
    color: theme.palette.grey[700],
    opacity: 'unset !important',
  },
}))

export const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  gap: theme.spacing(0.5),
}))

export const IconWrapper = styled(Box)({
  width: 20,
  display: 'flex',
})

export const Wrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(3),
}))
