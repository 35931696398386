import {
  useFormContext,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import {
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from '@mui/material'

export type TimeFieldProps = Partial<BaseSelectProps> & {
  name: UseControllerProps['name']
  timeFormat?: string
  timeFieldOptions: {
    title: string
    value: string
  }[]
}

const TimeField = ({
  name,
  label,
  timeFormat = 'HH:mm',
  timeFieldOptions,
  ...props
}: TimeFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue: timeFieldOptions[0].value })

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <BaseSelect
        label={label}
        inputRef={ref}
        labelId={`${name}-label`}
        {...inputProps}
        {...props}
      >
        {timeFieldOptions.map(({ title, value }) => (
          <MenuItem key={`menu-item-${title}-${value}`} value={value}>
            {title}
          </MenuItem>
        ))}
      </BaseSelect>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default TimeField
