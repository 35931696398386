import {
  InputLabel as BaseInputLabel,
  Typography,
  styled,
  RadioGroup as BaseRadioGroup,
} from '@mui/material';

const PREFIX = 'RadioField';
export const classes = {
  inputRoot: `${PREFIX}-inputRoot`,
  inputChecked: `${PREFIX}-inputChecked`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
};

export const InputLabel = styled(BaseInputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

export const ErrorWrapper = styled(Typography)(({ theme }) => ({
  marginTop: 3,
  width: '100%',
  color: theme.palette.error.main,
  fontSize: '0.75rem',
}));

interface ErrorStyleProps {
  error?: boolean;
}

export const RadioGroup = styled(BaseRadioGroup)<ErrorStyleProps>(
  ({ theme, error }) => ({
    [`&.${classes.error}`]: {
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: error ? theme.palette.error.main : 'transparent',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    [`& .${classes.inputRoot}`]: {
      '&$checked': {
        color: theme.palette.primary.main,
      },
    },
    [`& .${classes.inputChecked}`]: {},
    [`& .${classes.label}`]: {
      marginBottom: 1,
      color: error ? theme.palette.error.main : theme.palette.text.secondary,
    },
  })
);
