import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.home,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Dashboard'),
    }),
    admin: true,
  },
  {
    path: paths.signIn,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: { style: { justifyContent: 'center' } },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Login'),
    }),
  },
  {
    path: paths.signInWithLink,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: { style: { justifyContent: 'center' } },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/LoginWithLink'),
    }),
  },
  {
    path: paths.activateAccount,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: { style: { justifyContent: 'center' } },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/ActivateAccount'),
    }),
  },
  {
    path: paths.forgotPassword,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: { style: { justifyContent: 'center' } },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/ForgotPassword'),
    }),
  },
  {
    path: paths.resetPassword,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: { style: { justifyContent: 'center' } },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/ResetPassword'),
    }),
  },
]

export default routes
