import {
  List,
  ListProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  styled,
} from '@mui/material'
import { ExtendedOption } from 'shared/types'

interface MultiselectListProps<T, K> extends ListProps {
  options?: ExtendedOption<T, K>[]
  selectedValues: K[]
  onItemClick: (value: K) => any
  isLoading?: boolean
}

const StyledList = styled(List)({
  width: '100%',
})

const MultiselectList = <T, K>({
  selectedValues,
  onItemClick,
  options = [],
  isLoading = false,
  ...props
}: MultiselectListProps<T, K>) => (
  <StyledList {...props}>
    {options.map(({ title, value, disabled }) => {
      const isItemActive = selectedValues.indexOf(value) !== -1
      return (
        <ListItem key={`multiselect-list-item-${value}`} disablePadding>
          <ListItemButton
            role={undefined}
            onClick={() => onItemClick(value)}
            dense
            disabled={isLoading || disabled}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={isItemActive}
                tabIndex={-1}
                disableRipple
                disabled={isLoading || disabled}
              />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </ListItem>
      )
    })}
  </StyledList>
)

export default MultiselectList
