import { generateMinuteIntervals } from 'shared/utils'
import { addDays, format, startOfDay } from 'date-fns'
import { useMemo } from 'react'
import { Option } from 'shared/types'

export const useTimeFieldOptions = ({
  timeFormat,
  date,
}: {
  timeFormat: string
  date: Date | null | undefined
}): Option[] => {
  if (date && isNaN(new Date(date).getTime())) {
    date = null
  }

  const min = date ? startOfDay(date) : startOfDay(new Date())
  const max = date
    ? addDays(startOfDay(date), 1)
    : addDays(startOfDay(new Date()), 1)
  const intervals = useMemo(
    () => generateMinuteIntervals({ min, max, interval: 15 }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  )
  const options = useMemo(
    () =>
      intervals.map((i, index) => ({
        title: index + 1 === intervals.length ? '24:00' : format(i, timeFormat),
        value: i.toISOString(),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervals]
  )

  return options
}
