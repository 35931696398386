import {
  Icon,
  ReauthenticationVariant,
  ResourceType,
  SvgIconComponent,
} from 'shared/types'
import {
  Barrier,
  Garage,
  Door,
  Gate,
  Handle,
  Lift,
  Speedgate,
  Padlock,
} from 'shared/assets'
import { Board, Desk, Car } from 'shared/assets'
import { Locker } from 'shared/assets/globalworth'

export const ResourceTypeIconMapping: Record<ResourceType, SvgIconComponent> = {
  [ResourceType.ConferenceRoom]: Board,
  [ResourceType.Desk]: Desk,
  [ResourceType.ParkingSpot]: Car,
  [ResourceType.Locker]: Locker,
}

export const mapNumberToReauthenticationVariant = (
  value: number
): ReauthenticationVariant | null => {
  if (Object.values(ReauthenticationVariant).includes(value)) {
    return value as ReauthenticationVariant
  }
  return null
}

export const LockIconMapping: Record<Icon, SvgIconComponent> = {
  [Icon.Barrier]: Barrier,
  [Icon.Door]: Door,
  [Icon.Garage]: Garage,
  [Icon.Gate]: Gate,
  [Icon.Lock]: Padlock,
  [Icon.Handle]: Handle,
  [Icon.Lift]: Lift,
  [Icon.Speedgate]: Speedgate,
}
