import { Redirect, Route } from 'react-router-dom'
import { paths } from 'config'
import { useAuth } from 'shared/hooks'
import { LoadingStatus, ModuleRoute } from 'shared/types'
import PageLoader from './PageLoader'

const PrivateRoute = ({ public: isPublic, ...props }: ModuleRoute) => {
  const {
    isAuthenticated,
    user: { loading },
  } = useAuth()

  if ([LoadingStatus.Idle, LoadingStatus.Pending].includes(loading)) {
    return <PageLoader />
  }

  return isPublic || isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={paths.signIn} />
  )
}

export default PrivateRoute
