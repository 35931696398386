import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  CircularProgress,
  TypographyProps,
  GridProps,
  Typography,
  styled,
} from '@mui/material'

interface DynamicContentProps extends Partial<GridProps> {
  displayContent?: any
  loading?: boolean
  loaderSize?: number
  titleVariant?: TypographyProps['variant']
}

const Wrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
}))

const DynamicContent = ({
  loading = true,
  loaderSize = 50,
  displayContent = false,
  titleVariant = 'h6',
  children,
  ...props
}: PropsWithChildren<DynamicContentProps>) => {
  const { t } = useTranslation()

  return !loading && displayContent ? (
    <>{children}</>
  ) : (
    <Wrapper container justifyContent="center" alignItems="center" {...props}>
      {loading ? (
        <CircularProgress size={loaderSize} />
      ) : (
        <Typography variant={titleVariant}>{t('noResults')}</Typography>
      )}
    </Wrapper>
  )
}
export default DynamicContent
