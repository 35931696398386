import { HelpOutline } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { BuildingFaq } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.buildingFaqs,
    admin: false,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? BuildingFaq : HelpOutline,
      label: 'nav.buildingFaq',
    },
    component: Loadable({
      component: () => import('./pages/BuildingFaqs'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.buildingFaq,
    admin: false,
    exact: true,
    component: Loadable({
      component: () => import('./pages/BuildingFaq'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.buildingFaqsList',
          href: paths.buildingFaqs,
        },
      },
    },
  },
]

export default routes
