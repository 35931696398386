import { gql } from '@apollo/client'

export const CONFIRM_RESOURCE_BOOKING_WITH_CODE = gql`
  mutation ConfirmResourceBookingWithCodeMutation(
    $input: ConfirmResourceBookingWithCodeInput!
  ) {
    confirmResourceBookingWithCode(input: $input) {
      clientMutationId
    }
  }
`
