import { useConfirmBookingWithCode } from 'modules/bookingConfirmation/data/hooks'
import { createContext, PropsWithChildren, useState } from 'react'
import { LoadingStatus } from 'shared/types'

export interface ConfirmationContextType {
  loading: LoadingStatus
  confirmBooking: (code: string) => void
}
const CONTEXT_DEFAULTS: ConfirmationContextType = {
  loading: LoadingStatus.Idle,
  confirmBooking: () => {},
}

export const ConfirmationContext =
  createContext<ConfirmationContextType>(CONTEXT_DEFAULTS)

export const ConfirmationContextConsumer = ConfirmationContext.Consumer

interface ConfirmationContextProviderProps {}

export const ConfirmationContextProvider = ({
  children,
}: PropsWithChildren<ConfirmationContextProviderProps>) => {
  const [loading, setLoading] = useState<ConfirmationContextType['loading']>(
    CONTEXT_DEFAULTS.loading
  )

  const [confirmBookingWithCode] = useConfirmBookingWithCode()

  const confirmBooking = async (code: string) => {
    try {
      setLoading(LoadingStatus.Pending)
      const result = await confirmBookingWithCode({
        variables: {
          input: {
            code,
          },
        },
      })
      result.errors
        ? setLoading(LoadingStatus.Failed)
        : setLoading(LoadingStatus.Succeeded)
    } catch (e) {
      setLoading(LoadingStatus.Failed)
    }
  }

  return (
    <ConfirmationContext.Provider
      value={{
        loading,
        confirmBooking,
      }}
    >
      {children}
    </ConfirmationContext.Provider>
  )
}
