const env = <const>{
  apiUrl: process.env.REACT_APP_API_URL || '',
  appIdentifier: process.env.REACT_APP_APP_IDENTIFIER || '',
  firebaseConfig: process.env.REACT_APP_FIREBASE_CONFIG || '{}',
  appUrl: process.env.REACT_APP_URL || '',
  appAndroidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME || '',
  appIosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID || '',
  productFlavor: process.env.REACT_APP_PRODUCT_FLAVOR || '',
  continueUrlRedirect: process.env.REACT_APP_CONTINUE_URL_REDIRECT || true,
  firebaseAppCheckDebug: process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG || null,
  firebaseAppCheckSiteKey:
    process.env.REACT_APP_FIREBASE_APPCHECK_SITE_KEY || null,
}

Object.entries(env).forEach(([key, value]) => {
  if (!value) {
    console.error(`Missing ${key} env variable.`)
  }
})

export default env
