import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ErrorText,
  ToggleButton,
  ToggleButtonGroup,
  Wrapper,
} from './DayOfWeekPicker.style'

interface DayOfWeekPickerProps {
  onChange: (daysOfWeek: string[]) => void
  error: FieldError[] | undefined
  currentDays?: string[]
  disabled?: boolean
}

const formatDays = (days: string[]) =>
  days.map(day => day.charAt(0) + day.slice(1).toLowerCase())

const DayOfWeekPicker = ({
  onChange,
  error,
  currentDays,
  disabled = false,
}: DayOfWeekPickerProps) => {
  const { t } = useTranslation()

  const [selectedDays, setSelectedDays] = useState<string[]>(
    formatDays(currentDays || [])
  )

  const daysOfWeek = eachDayOfInterval({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  }).map(date => ({
    localizedName: format(date, 'iiiiii'),
    englishName: format(date, 'iiiiii', { locale: enUS }),
  }))

  const handleDayChange = (
    event: React.MouseEvent<HTMLElement>,
    newDays: string[]
  ) => {
    setSelectedDays(newDays)
    onChange(newDays.map(day => day.toUpperCase()))
  }

  return (
    <Wrapper>
      <ToggleButtonGroup
        value={selectedDays}
        onChange={handleDayChange}
        aria-label="day of week"
        disabled={disabled}
      >
        {daysOfWeek.map(day => (
          <ToggleButton
            key={day.englishName}
            value={day.englishName}
            aria-label={day.localizedName}
          >
            {t(day.localizedName)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {error && selectedDays.length === 0 && (
        <ErrorText variant="caption">{t('validation.required')}</ErrorText>
      )}
    </Wrapper>
  )
}
export default DayOfWeekPicker
