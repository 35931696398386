import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import {
  CardFormat,
  ExternalSystemField,
  ExternalSystemFieldDataType,
} from 'shared/types'
import { Subtitle, Row } from './ExternalSystemFieldRow.style'

interface ExternalSystemFieldRowProps extends ExternalSystemField {
  onSave: (id: string, value: string) => void
}

const ExternalSystemFieldRow = ({
  value,
  node: { id, title, summary, dataType, regexPattern },
  onSave,
}: ExternalSystemFieldRowProps) => {
  const [inputValue, setInputValue] = useState<string>(value || '')
  const [isValid, setIsValid] = useState(true)
  const validationRegex = new RegExp(regexPattern)

  const handleInputValueChange = (newValue: string) => {
    if (!validationRegex) return
    setInputValue(newValue)
    if (validationRegex.test(newValue)) {
      if (isValid) return
      setIsValid(true)
    } else {
      if (!isValid) return
      setIsValid(false)
    }
  }

  const handleSelectValueChange = (newValue: string) => {
    if (!validationRegex) return
    setInputValue(newValue)
    if (validationRegex.test(newValue)) {
      if (newValue !== value) {
        onSave(id, newValue)
      }
      if (isValid) return
      setIsValid(true)
    } else {
      if (!isValid) return
      setIsValid(false)
    }
  }

  const handleSave = () => {
    if (!inputValue || !isValid || inputValue === value) return
    onSave(id, inputValue)
  }

  return (
    <Row container>
      <Grid item md={4} xs={5}>
        <Typography>{title}</Typography>
        <Subtitle>{summary}</Subtitle>
      </Grid>
      <Grid item md={8} xs={7}>
        {dataType === ExternalSystemFieldDataType.CardFormat ? (
          <Select
            fullWidth={true}
            value={inputValue}
            onChange={e => handleSelectValueChange(e.target.value)}
            error={!isValid}
          >
            <MenuItem value="" disabled>
              <span>&nbsp;</span>
            </MenuItem>
            {(Object.values(CardFormat) as Array<keyof typeof CardFormat>).map(
              key => (
                <MenuItem value={key} key={key}>
                  {key}
                </MenuItem>
              )
            )}
          </Select>
        ) : (
          <TextField
            fullWidth={true}
            value={inputValue}
            onChange={e => handleInputValueChange(e.target.value)}
            error={!isValid}
            onBlur={handleSave}
          />
        )}
      </Grid>
    </Row>
  )
}

export default ExternalSystemFieldRow
