import { useState } from 'react'
import { InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import TextField, { TextFieldProps } from './TextField'
import { isGlobalworth } from 'config'
import { Hide, Show } from 'shared/assets'

type PasswordFieldProps = TextFieldProps & {}

const PasswordField = (props: PasswordFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const toggleIsPasswordVisible = () =>
    setIsPasswordVisible(isVisible => !isVisible)

  const VisibilityOffIcon = isGlobalworth ? <Hide /> : <VisibilityOff />
  const VisibilityIcon = isGlobalworth ? <Show /> : <Visibility />

  return (
    <TextField
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleIsPasswordVisible} edge="end">
              {isPasswordVisible ? VisibilityOffIcon : VisibilityIcon}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
export default PasswordField
