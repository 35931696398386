import {
  Typography,
  Select as BaseSelect,
  Checkbox as BaseCheckbox,
  MenuItem as BaseMenuItem,
  ListItemText as BaseListItemText,
  InputBase,
  styled,
} from '@mui/material'
import { Filter } from 'shared/assets'

export const Icon = styled(Filter)(({ theme }) => ({
  stroke: 'inherit',
  marginRight: theme.spacing(0.75),
  width: 15,
  height: 15,
  transform: 'translateY(-10%)',
  color: theme.palette.primary.main,
}))

export const FilterTitle = styled(Typography)(({ theme }) => ({
  color: 'inherit',
  fontSize: 12,
  lineHeight: '14px',
}))

export const Select = styled(BaseSelect)(({ theme }) => ({
  color: 'inherit',
  cursor: 'pointer',
  fontSize: 12,
  lineHeight: '14px',
  '& > svg[class*=MuiSvgIcon-root]': {
    display: 'none',
  },
  '& div[class*=MuiSelect-select]': {
    color: theme.palette.primary.main,
    paddingRight: `0px !important`,
  },
}))

export const MenuItem = styled(BaseMenuItem)(({ theme }) => ({}))

export const Checkbox = styled(BaseCheckbox)(({ theme }) => ({}))

export const ListItemText = styled(BaseListItemText)(({ theme }) => ({}))

export const Input = styled(InputBase)(({ theme }) => ({}))
