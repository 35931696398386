import { styled, Box, Grid, FormControlLabel } from '@mui/material'

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
}))

export const SearchWrapper = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(0.5, 0, 2),
}))

export const PaginationWrapper = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 0, 0),
}))

export const ListWrapper = styled(Grid)({
  flex: 1,
  overflowY: 'scroll',
})

export const Container = styled(Grid)({
  flexDirection: 'column',
  height: '100%',
  flexWrap: 'nowrap',
  display: 'flex',
})

export const CheckboxWrapper = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0.5, 2),
}))
