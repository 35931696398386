import {
  Drawer,
  Typography,
  styled,
  Toolbar as BaseToolbar,
} from '@mui/material'
import { Link as BaseLink } from 'react-router-dom'
import { Logo as BaseLogo } from 'shared/assets'

export const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  padding: theme.spacing(1, 0),
}))

export const DRAWER_WIDTH = 240

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '60%',
    color: theme.palette.primary.light,
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
    },
    boxSizing: 'border-box',
  },
}))

export const Title = styled(Typography)({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    boxSizing: 'border-box',
  },
})

export const Link = styled(BaseLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}))

export const Logo = styled(BaseLogo)({
  width: 120,
})

export const Toolbar = styled(BaseToolbar)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
})
