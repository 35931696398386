import { Fragment, useState } from 'react'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  Checkbox,
  Collapse,
  styled,
  Divider,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  NestedMultiselectListSelectedValue,
  NestedMultiselectListOption,
} from '.'

interface NestedMultiselectListItemProps<T, K> extends Partial<ListItemProps> {
  options: Omit<NestedMultiselectListOption<T, K>, 'subItems'>[]
  selectedValue: NestedMultiselectListSelectedValue<K> | null
  title: string
  subtitle?: T
  onClick: () => any
  onItemClick: (value: K | any) => void
  disabled: boolean
}

const NestedMultiselectListItem = <T, K>({
  selectedValue,
  onClick,
  onItemClick,
  title,
  options,
  subtitle,
  disabled,
  ...props
}: NestedMultiselectListItemProps<T, K>) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const hasOptions = options.length > 0
  const isAnySelected = selectedValue !== null

  return (
    <Wrapper disablePadding {...props}>
      <ListItemButton
        role={undefined}
        onClick={e => {
          setIsExpanded(!isExpanded)
        }}
        dense
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isAnySelected}
            tabIndex={-1}
            disableRipple
            onClick={e => {
              e.stopPropagation()
              !disabled && onClick()
            }}
            disabled={disabled}
          />
        </ListItemIcon>
        <ListItemText primary={title} secondary={subtitle} />
        {hasOptions && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {hasOptions && (
        <Collapse in={isExpanded}>
          <Divider />
          <List component="div" disablePadding>
            {options.map(({ title, value, subtitle }) => (
              <Fragment key={`multiselect-list-child-${value}`}>
                <ListItem
                  key={`multiselect-list-child-item-${value}`}
                  disablePadding
                  component="div"
                >
                  <ChildItemButtonWrapper
                    onClick={() => !disabled && onItemClick(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          isAnySelected
                            ? selectedValue.subItems.includes(value)
                            : false
                        }
                        tabIndex={-1}
                        disableRipple
                        disabled={disabled}
                      />
                    </ListItemIcon>
                    <ChildItemText primary={title} secondary={subtitle} />
                  </ChildItemButtonWrapper>
                </ListItem>
                {options.at(-1)?.value !== value && <Divider sx={{ ml: 6 }} />}
              </Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </Wrapper>
  )
}

export default NestedMultiselectListItem

const Wrapper = styled(ListItem)({
  flexDirection: 'column',
  alignItems: 'stretch',
})

const ChildItemButtonWrapper = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 6),
}))

const ChildItemText = styled(ListItemText)({
  '& > span': {
    fontSize: '0.875rem',
  },
})
