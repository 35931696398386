import env from './env'

const getFirebaseConfig = () => {
  try {
    const firebaseConfig = JSON.parse(env.firebaseConfig)
    return firebaseConfig
  } catch (error) {
    console.error(error)
  }
}

export default getFirebaseConfig()
