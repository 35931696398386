import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER, GET_CURRENT_USER_ACCESS_DETAILS } from './queries'
import { GetCurrentUserAccessDetailsQuery, GetCurrentUserQuery } from './types'

export const useCurrentUser = () =>
  useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first',
  })

export const useCurrentUserAccessDetails = () =>
  useQuery<GetCurrentUserAccessDetailsQuery>(GET_CURRENT_USER_ACCESS_DETAILS, {
    fetchPolicy: 'cache-first', //consider changing to 'no-cache'
  })
