import { useState } from 'react'

export const useMultiselect = () => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const handleToggle = (value: string) =>
    setSelectedValues(values =>
      values.includes(value)
        ? values.filter(v => v !== value)
        : [...values, value]
    )

  return {
    selectedValues,
    handleToggle,
    clearValues: () => setSelectedValues([]),
    setSelectedValues,
  }
}
