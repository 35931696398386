import { FirebaseAuth } from 'shared/services'
import env from './env'

export const getRequestHeaders = async (auth: FirebaseAuth) => {
  let authToken = ''
  try {
    const authTokenResult = await auth.getIdToken()
    if (authTokenResult) {
      authToken = authTokenResult
    }
  } catch (e) {
    console.error(e)
  }
  let appCheckToken = ''
  try {
    const result = await auth.getAppCheckToken()
    if (result) {
      appCheckToken = result.token
    }
  } catch (e) {
    console.error(e)
  }

  return {
    authorization: authToken ? `Bearer ${authToken}` : '',
    'X-App-Identifier': env.appIdentifier,
    ...(appCheckToken && { 'X-AppCheck-Token': appCheckToken }),
  }
}
