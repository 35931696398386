import { Grid } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3, 0),
  height: '100%',
  '& > .MuiGrid-container': {
    height: '100%',
  },
  position: 'relative',
}))

export const ChildrenContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
}))
