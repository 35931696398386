interface Lock {
  value: string
  subItems: string[]
}

export const useLocksListSelect = (
  selectedLocks: Lock[],
  setValue: (locks: Lock[]) => void
) => {
  const onSelect = (lockId: string, optionId: string | string[]) => {
    const selectedLock = selectedLocks.find(it => it.value === lockId)
    const isLockSelected = !!selectedLock

    // on lock click - if lock is selected, then delete lock with all options, otherwise select lock and all available options
    if (Array.isArray(optionId)) {
      return setValue(
        isLockSelected
          ? selectedLocks.filter(item => item.value !== lockId)
          : [...selectedLocks, { value: lockId, subItems: optionId }]
      )
    }

    // on lock option click when lock is not selected and option is not selected too
    if (!isLockSelected) {
      return setValue([
        ...selectedLocks,
        { value: lockId, subItems: [optionId] },
      ])
    }

    // on lock option click when lock is selected  but option is not selected
    if (isLockSelected && !selectedLock.subItems.includes(optionId)) {
      return setValue(
        selectedLocks.map(it => {
          if (it.value !== lockId) return it
          return { value: lockId, subItems: [...it.subItems, optionId] }
        })
      )
    }

    // on lock option click when lock is selected and option is selected too
    // and it was last selected option in lock - remove lock
    if (selectedLock.subItems.length <= 1) {
      return setValue(selectedLocks.filter(it => it.value !== lockId))
    }

    // and it was not last option - remove only clicked option
    const currentLocks = selectedLocks.map(lock => {
      const { subItems, value } = lock
      if (value !== lockId) return lock
      return {
        value: lockId,
        subItems: subItems.includes(optionId)
          ? subItems.filter(item => item !== optionId)
          : [...subItems, optionId],
      }
    })
    setValue(currentLocks)
  }

  return { onSelect }
}
