import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stepper as BaseStepper,
  Step,
  StepLabel,
  Grid,
  Fade,
} from '@mui/material'
import { Step as StepType } from 'shared/types'
import { Header, Button } from './FormStepper.style'

interface FormStepperProps {
  isLoading?: boolean
  steps?: StepType[]
}

const FormStepper = ({ steps = [], isLoading }: FormStepperProps) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const isLastStep = activeStep === steps.length - 1
  const onNextClick = () => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1)
    }
  }
  const onPrevClick = () => activeStep > 0 && setActiveStep(activeStep - 1)

  return (
    <>
      <Grid container>
        <Header item xs={9}>
          <BaseStepper activeStep={activeStep}>
            {steps.map(({ label }) => (
              <Step expanded key={`stepper-item-${label}`}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </BaseStepper>
        </Header>
      </Grid>
      {steps.length && steps[activeStep].component}
      <Grid container justifyContent="flex-end">
        <Fade in={activeStep > 0}>
          <Button
            fullWidth={false}
            color="primary"
            variant="outlined"
            onClick={onPrevClick}
          >
            {t('prev')}
          </Button>
        </Fade>
        <Button
          fullWidth={false}
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={onNextClick}
          disabled={steps[activeStep].isNextDisabled}
          type={isLastStep ? 'submit' : 'button'}
        >
          {t(isLastStep ? 'create' : 'next')}
        </Button>
      </Grid>
    </>
  )
}

export default FormStepper
