interface DownloadBlobProps {
  fileName: string
  download: () => Promise<Blob>
  onSuccess?: () => void
  onError: () => void
}

export const downloadBlob = async ({
  download,
  onSuccess,
  onError,
  fileName,
}: DownloadBlobProps) => {
  try {
    const blob = await download()
    const url = URL.createObjectURL(blob)
    const linkElement = document.createElement('a')
    linkElement.href = url
    linkElement.setAttribute('download', fileName)
    document.body.appendChild(linkElement)
    linkElement.click()
    onSuccess && onSuccess()
  } catch (e) {
    console.error(e)
    onError()
  }
}
