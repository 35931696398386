import {
  Box,
  CircularProgress,
  CircularProgressProps,
  styled,
} from '@mui/material'

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
})

interface LoaderProps {
  circularProgressProps?: Partial<CircularProgressProps>
}

export const Loader = ({ circularProgressProps }: LoaderProps) => {
  return (
    <Wrapper>
      <CircularProgress {...circularProgressProps} />
    </Wrapper>
  )
}

export default Loader
