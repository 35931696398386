import { isGlobalworth, paths } from 'config'
import { Tutorial } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.tutorial,
    linkSettings: {
      icon: Tutorial,
      label: 'nav.tutorial',
    },
    admin: false,
    exact: true,
    component: Loadable({
      component: isGlobalworth
        ? () => import('./tutorialGW/pages/Tutorial')
        : () => import('./tutorialBB/pages/Tutorial'),
    }),
  },
]

export default routes
