import { useMemo, forwardRef } from 'react'
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemProps, ListItemText } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { SvgIconComponent as CustomSvgIconComponent } from 'shared/types'
import { paths } from 'config'
import ListItemIcon from './ListItemIcon'

const ListItemLink = ({ icon: Icon, label, to, ...props }: any) => {
  const { t } = useTranslation()
  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem button component={renderLink} {...props}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        {label && (
          <ListItemText
            primary={t(label)}
            primaryTypographyProps={{ color: 'inherit' }}
          />
        )}
      </ListItem>
    </li>
  )
}

export interface DrawerItemProps extends Partial<ListItemProps> {
  label?: string
  icon?: SvgIconComponent | CustomSvgIconComponent
  path: string
}

const DrawerItem = ({ path, ...props }: DrawerItemProps) => {
  const { pathname } = useLocation()
  const match = matchPath(pathname, { path, exact: path === paths.home })

  return (
    <ListItemLink
      to={path}
      {...props}
      className={`${props.className || ''} ${match ? 'Mui-selected' : ''}`}
    />
  )
}

export default DrawerItem
