import { createTheme, ThemeOptions } from '@mui/material'
import palette from './palette'
import components from './components'

const theme: ThemeOptions = {
  components,
  palette,
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
}

export default createTheme(theme)
