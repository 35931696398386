import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.deleteAccount,
    admin: false,
    exact: true,
    public: true,
    layoutSettings: {
      contentProps: {
        style: { justifyContent: 'center', alignItems: 'center' },
      },
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/DeleteAccount'),
    }),
  },
]

export default routes
