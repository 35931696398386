import {
  styled,
  ToggleButton as BaseToggleButton,
  ToggleButtonGroup as BaseToggleButtonGroup,
  Typography,
  Box,
} from '@mui/material'

export const ToggleButton = styled(BaseToggleButton, {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  width: 42,
  height: 42,
  borderRadius: '50%',
  '&.Mui-selected': {
    color: theme.palette.common.white,
    ...(disabled
      ? {
          backgroundColor: theme.palette.grey[500],
        }
      : {
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
  },
  textTransform: 'none',
}))

export const ToggleButtonGroup = styled(BaseToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(1),
  width: '100%',
  overflow: 'auto',
  '& .MuiToggleButton-root': {
    borderTopLeftRadius: '50% !important',
    borderBottomLeftRadius: '50% !important',
    borderTopRightRadius: '50% !important',
    borderBottomRightRadius: '50% !important',
    marginLeft: '0 !important',
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
  },
}))

export const ErrorText = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  color: theme.palette.error.main,
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  maxWidth: '100%',
}))
