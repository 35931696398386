export type ValueOf<T> = T[keyof T]

export type NextFn<T> = (value: T) => void

export type Option<T = string, K = string> = {
  title: T
  subtitle?: T | string
  value: K
}

export interface ExtendedOption<T = string, K = string> extends Option<T, K> {
  disabled?: boolean
}

export type WithLoading<T, V = boolean> = T & {
  loading: V
}

export type ListItemData<T = {}> = T & {
  id: string
  name: string
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined
}
