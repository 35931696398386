import { useTranslation } from 'react-i18next'
import { useErrorSnackbar } from './use-error-snackbar'
import { useAuth } from './use-auth'
import { useConsents } from './use-consents'
import { MODULE_NAME } from 'modules/userPreferences/strings'

export const useRegulationsValidator = () => {
  const [showError] = useErrorSnackbar()
  const { t } = useTranslation(MODULE_NAME)
  const { signOut } = useAuth()
  const { hasAcceptedAllCurrentPreferences } = useConsents()

  const checkUserHasValidConsents = () => {
    if (!hasAcceptedAllCurrentPreferences()) {
      signOut()
      showError(t('alert.newVersion.message'))
    }
  }

  return {
    checkUserHasValidConsents,
  }
}
