import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { SnackbarProvider } from 'notistack'
import { theme, getDataClient } from 'config'
import modules from 'modules'
import {
  AuthProvider,
  PageLoader,
  Layout,
  ConfirmationContextProvider,
  RemoteConfigProvider,
} from 'shared/components'
import { useCurrentUser } from 'shared/hooks'
import useLocale from 'shared/hooks/use-locale'
import AuthLoader from 'shared/components/AuthLoader'

const App = () => {
  const user = useCurrentUser()
  const locale = useLocale()
  const dataClient = getDataClient(user.auth)

  return (
    <AuthProvider value={user}>
      <RemoteConfigProvider auth={user.auth}>
        <ApolloProvider client={dataClient}>
          <SnackbarProvider>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={locale}
              >
                <CssBaseline />
                <AuthLoader user={user}>
                  <Suspense fallback={<PageLoader />}>
                    <ConfirmationContextProvider>
                      <BrowserRouter>
                        <Layout routes={modules} />
                      </BrowserRouter>
                    </ConfirmationContextProvider>
                  </Suspense>
                </AuthLoader>
              </LocalizationProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </RemoteConfigProvider>
    </AuthProvider>
  )
}

export default App
