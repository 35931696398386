import {
  Components,
  IconButton,
  IconButtonProps,
  StepIconProps,
} from '@mui/material'
import { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import palette from './palette'
import type {} from '@mui/x-data-grid/themeAugmentation'
import type {} from '@mui/lab/themeAugmentation'
import {
  ArrowLeft,
  ArrowRight,
  DatePickerCalendar,
  ArrowDown,
} from 'shared/assets/globalworth'
/**
 * This maps Material-UI's link behavior to React Router.
 */
const RouterLink = forwardRef<
  any,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(({ href, ...props }, ref) => <Link ref={ref} to={href} {...props} />)

const StepLabel = ({ icon, completed, active }: StepIconProps) => {
  return (
    <div
      style={{
        color: palette.primary.contrastText,
        backgroundColor:
          completed || active ? palette.primary.main : palette.grey[300],
        width: '1.5rem',
        height: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
    </div>
  )
}

const UncheckedCheckbox = () => (
  <div
    style={{
      width: '1rem',
      height: '1rem',
      margin: 4,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderWidth: 1,
    }}
  />
)

const CheckedCheckbox = () => (
  <div
    style={{
      width: '1rem',
      height: '1rem',
      margin: 4,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderWidth: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        width: '0.75rem',
        height: '0.75rem',
        backgroundColor: palette.primary.main,
      }}
    />
  </div>
)

const UncheckedRadio = () => (
  <div
    style={{
      width: '1rem',
      height: '1rem',
      margin: 4,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderWidth: 1,
      borderRadius: '50%',
    }}
  />
)

const CheckedRadio = () => (
  <div
    style={{
      width: '1rem',
      height: '1rem',
      margin: 4,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderWidth: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
    }}
  >
    <div
      style={{
        width: '0.75rem',
        height: '0.75rem',
        backgroundColor: palette.primary.main,
        borderRadius: '50%',
      }}
    />
  </div>
)

const LeftButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowLeft
      style={{
        height: 24,
        width: 24,
        padding: 4,
        color: props.disabled ? 'inherit' : palette.primary.main,
      }}
    />
  </IconButton>
)

const RightButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowRight
      style={{
        height: 24,
        width: 24,
        padding: 4,
        color: props.disabled ? 'inherit' : palette.primary.main,
      }}
    />
  </IconButton>
)

const SwitchViewButton = (props: IconButtonProps) => (
  <IconButton {...props}>
    <ArrowDown
      style={{
        height: 24,
        width: 24,
        padding: 4,
        color: props.disabled ? 'inherit' : palette.primary.main,
      }}
    />
  </IconButton>
)

const overrides: Components = {
  MuiButton: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiLink: {
    defaultProps: {
      // @ts-ignore
      component: RouterLink,
      underline: 'hover',
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiTabs: {
    defaultProps: {
      sx: {
        //borderBottomStyle: 'solid',
        //borderBottomColor: palette.grey[300],
        //borderBottomWidth: 1,
        //marginBottom: 2.5,
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      PaperProps: {
        sx: {
          borderRightColor: palette.primary.main,
        },
      },
    },
  },
  MuiStepLabel: {
    defaultProps: {
      StepIconComponent: props => <StepLabel {...props} />,
    },
  },
  MuiCheckbox: {
    defaultProps: {
      icon: <UncheckedCheckbox />,
      checkedIcon: <CheckedCheckbox />,
      sx: {
        borderRadius: 0,
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      icon: <UncheckedRadio />,
      checkedIcon: <CheckedRadio />,
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        sx: {
          borderRadius: 0,
          padding: '6px',
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      sx: {
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          borderRadius: '0px',
        },
        '& .MuiSwitch-track': {
          backgroundColor: palette.text.primary,
          borderRadius: '0px',
        },
      },
    },
  },
  MuiSlider: {
    defaultProps: {
      sx: {
        '& .MuiSlider-thumb': {
          borderRadius: '0px',
        },
        '& .MuiSlider-rail': {
          borderRadius: '0px',
        },
      },
    },
  },
  MuiDatePicker: {
    defaultProps: {
      components: {
        OpenPickerIcon: DatePickerCalendar,
        RightArrowIcon: ArrowRight,
        LeftArrowButton: LeftButton,
        RightArrowButton: RightButton,
        SwitchViewButton: SwitchViewButton,
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      sx: {
        '.MuiTouchRipple-child': {
          borderRadius: 0,
        },
        borderRadius: 0,
      },
    },
  },
}

export default overrides
