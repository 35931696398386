import { Grid, styled, Typography as TypographyBase } from '@mui/material'

export const Wrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

export const Typography = styled(TypographyBase)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const Label = styled(TypographyBase)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
