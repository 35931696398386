import { Divider, List, ListItem, ListItemText } from '@mui/material'
import { DynamicContent } from 'shared/components'
import { ExtendedOption } from 'shared/types'
import {
  Container,
  PaginationWrapper,
  BottomButtonWrapper,
  SearchWrapper,
  ListWrapper,
} from './OptionsList.style'
import { Fragment } from 'react'

interface OptionsListProps<T, K> {
  options: ExtendedOption<T, K>[]
  loading: boolean
  PaginationComponent?: React.ReactNode
  SearchComponent?: React.ReactNode
  BottomButtonComponent?: React.ReactNode
  actionsComponent?: (option: ExtendedOption<T, K>) => React.ReactNode
}

const OptionsList = <T, K>({
  options,
  loading,
  PaginationComponent,
  SearchComponent,
  BottomButtonComponent,
  actionsComponent,
}: OptionsListProps<T, K>) => {
  return (
    <Container container>
      {SearchComponent && <SearchWrapper item>{SearchComponent}</SearchWrapper>}
      <ListWrapper item>
        <DynamicContent
          loading={loading}
          displayContent={options.length}
          loaderSize={75}
        >
          <List disablePadding>
            {options.map(option => (
              <Fragment key={`option-item-${option.value}`}>
                <ListItem key={`list-item-${option.value}`}>
                  <ListItemText
                    primary={option.title}
                    secondary={option.subtitle}
                  />
                  {actionsComponent && actionsComponent(option)}
                </ListItem>
                {options.at(-1)?.value !== option.value && <Divider />}
              </Fragment>
            ))}
          </List>
        </DynamicContent>
      </ListWrapper>
      {PaginationComponent && (
        <PaginationWrapper item>{PaginationComponent}</PaginationWrapper>
      )}
      {BottomButtonComponent && (
        <BottomButtonWrapper>{BottomButtonComponent}</BottomButtonWrapper>
      )}
    </Container>
  )
}

export default OptionsList
