export const createQueryParams = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams()

  Object.keys(params).forEach(key => {
    const paramValue = params[key]
    if (Array.isArray(paramValue) && paramValue.length > 0) {
      if (paramValue.length === 1) {
        queryParams.append(`${key}[0]`, paramValue[0])
      } else {
        paramValue.forEach((val: any) => {
          queryParams.append(key, val)
        })
      }
    } else if (paramValue && !Array.isArray(paramValue)) {
      queryParams.append(key, paramValue)
    }
  })
  return queryParams
}
