import { useState } from 'react'
import { startOfISOWeek, endOfISOWeek } from 'date-fns'

export const useCalendar = () => {
  const [date, setDate] = useState<Date>(new Date())

  const onDateClick = (date: Date) => {
    setDate(date)
  }

  const onNavigate = (date: Date) => {
    setDate(date)
  }

  const weekStart = startOfISOWeek(date)
  const weekEnd = endOfISOWeek(date)

  return {
    date,
    onDateClick,
    onNavigate,
    weekStart,
    weekEnd,
  }
}
