import { useTranslation } from 'react-i18next'
import { SvgIconComponent } from '@mui/icons-material'
import { SvgIconComponent as CustomSvgIconComponent } from 'shared/types'
import {
  ListItem,
  ListItemButton,
  ListItemProps,
  ListItemText,
} from '@mui/material'
import ListItemIcon from './ListItemIcon'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { isGlobalworth } from 'config'
import { ArrowDown, ArrowUp } from 'shared/assets'

export interface DrawerItemProps extends Partial<ListItemProps> {
  label?: string
  icon?: SvgIconComponent | CustomSvgIconComponent
  onClick(): void
  expanded?: boolean
}

const ExpandLessIcon = isGlobalworth ? <ArrowUp /> : <ExpandLess />
const ExpandMoreIcon = isGlobalworth ? <ArrowDown /> : <ExpandMore />

const DrawerItem = ({ label, onClick, icon, expanded }: DrawerItemProps) => {
  const { t } = useTranslation()
  const Icon = icon
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        {label && (
          <ListItemText
            primary={t(label)}
            primaryTypographyProps={{ color: 'inherit' }}
          />
        )}
        {expanded === undefined
          ? null
          : expanded
          ? ExpandLessIcon
          : ExpandMoreIcon}
      </ListItemButton>
    </ListItem>
  )
}

export default DrawerItem
