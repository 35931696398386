import { useFormContext, useController } from 'react-hook-form';
import { ChangeEvent } from 'react';
import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
} from '@mui/material';
import { InputLabel, ErrorWrapper, classes } from './RadioField.style';

export interface RadioFieldItem {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
}

export type RadioFieldProps = RadioGroupProps & {
  name: string;
  label: string;
  required?: boolean;
  options: RadioFieldItem[];
};

const RadioField = ({
  name,
  label,
  options,
  defaultValue,
  required,
  ...props
}: RadioFieldProps) => {
  const { control, setValue, clearErrors } = useFormContext();
  const {
    field: { value },
    fieldState: { error },
  } = useController({ control, name, defaultValue });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value, { shouldDirty: true });
    clearErrors(name);
  };

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <RadioGroup
        name={name}
        onChange={handleChange}
        className={classes.error}
        {...props}
      >
        {options.map((option) => (
          <FormControlLabel
            key={`radio-${name}/${option.label}`}
            value={option.value}
            control={
              <Radio
                classes={{
                  root: classes.inputRoot,
                  checked: classes.inputChecked,
                }}
              />
            }
            label={option.label}
            checked={option.value === value}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
      {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
    </>
  );
};

export default RadioField;
