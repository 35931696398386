export const CODE_QUERY_PARAM = 'oobCode'

export const MODE_QUERY_PARAM = 'mode'

export const CONTINUE_URL_QUERY_PARAM = 'continueUrl'

export const REAUTHENTICATE_QUERY_PARAM = 'reauthenticate'

export const CHANGE_PASSWORD_QUERY_PARAM = 'changePassword'

export const DELETE_ACCOUNT_QUERY_PARAM = 'deleteAccount'

export const REAUTHENTICATION_STATUS_QUERY_PARAM = 'reauthenticationStatus'

export const REAUTHENTICATION_VARIANT_QUERY_PARAM = 'reauthenticationVariant'
