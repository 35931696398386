import {
  MenuProps,
  MenuItem as BaseMenuItem,
  Menu as BaseMenu,
  Button as BaseButton,
  styled,
  alpha,
} from '@mui/material/'

export const Menu = styled((props: MenuProps) => (
  <BaseMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 120,
    color: theme.palette.text.primary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  gap: theme.spacing(0.5),
}))

export const Button = styled(BaseButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))
