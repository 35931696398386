import { DirectionsCar } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { Resources } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.resources,
    admin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Resources : DirectionsCar,
      label: 'nav.resources',
    },
    component: Loadable({
      component: () => import('./pages/Resources'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.addResource,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddResource'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.resourcesList',
          href: paths.resources,
        },
      },
    },
  },
  {
    path: paths.editResource,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/EditResource'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.resourcesList',
          href: paths.resources,
        },
      },
    },
  },
  {
    path: paths.resource,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Resource'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.resourcesList',
          href: paths.resources,
        },
      },
    },
  },
]

export default routes
