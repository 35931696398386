import { GridProps } from '@mui/material'
import { Wrapper } from './TabPanel.styles'
interface TabPanelProps extends GridProps {
  value: number
  index: number
}

const TabPanel = ({ value, index, children, ...props }: TabPanelProps) => {
  return value === index ? <Wrapper {...props}>{children}</Wrapper> : <></>
}

export default TabPanel
