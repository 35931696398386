import { useFormContext, useController } from 'react-hook-form'
import { MobileDatePicker, MobileDatePickerProps } from '@mui/lab'
import { TextField } from '@mui/material'
import { Tooltip } from '@mui/material'
import { clsx } from 'shared/utils'
import { startOfDay } from 'date-fns'

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'

export interface DateFieldProps extends Partial<MobileDatePickerProps> {
  name: string
  defaultValue?: Date | string | null
  parseToIsoString?: boolean
  disabledMessage?: string
}

const DateField = ({
  name,
  label,
  defaultValue = null,
  parseToIsoString = false,
  disabled,
  disabledMessage,
  renderInput,
  className,
  ...props
}: DateFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({ control, name, defaultValue })

  const handleChange = (date: any) => {
    try {
      date
        ? onChange(parseToIsoString ? date.toISOString() : startOfDay(date))
        : onChange(null)
    } catch {
      onChange(null)
    }
  }

  return disabledMessage ? (
    <Tooltip
      title={disabledMessage}
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      disableTouchListener={!disabled}
    >
      <span>
        <MobileDatePicker
          label={label}
          inputRef={ref}
          onChange={handleChange}
          disabled={disabled}
          inputFormat={DEFAULT_DATE_FORMAT}
          renderInput={props => (
            <TextField
              {...props}
              className={clsx(props.className, className)}
              error={!!error}
              helperText={error?.message}
            />
          )}
          {...inputProps}
          {...props}
        />
      </span>
    </Tooltip>
  ) : (
    <MobileDatePicker
      label={label}
      inputRef={ref}
      onChange={handleChange}
      disabled={disabled}
      inputFormat={DEFAULT_DATE_FORMAT}
      renderInput={props => (
        <TextField
          {...props}
          className={clsx(props.className, className)}
          error={!!error}
          helperText={error?.message}
        />
      )}
      {...inputProps}
      {...props}
    />
  )
}

export default DateField
