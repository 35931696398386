import { useTranslation } from 'react-i18next'
import pl from 'date-fns/locale/pl'
import enGB from 'date-fns/locale/en-GB'
import ro from 'date-fns/locale/ro'
import setDefaultOptions from 'date-fns/setDefaultOptions'

const useLocale = () => {
  enum Languages {
    POLISH = 'pl',
    ENGLISH = 'en',
    ROMANIAN = 'ro',
  }
  const { t } = useTranslation('common', { useSuspense: false })
  const language = t('current_language')

  const getLocale = () => {
    switch (language) {
      case Languages.POLISH:
        return pl
      case Languages.ENGLISH:
        return enGB
      case Languages.ROMANIAN:
        return ro
      default:
        return enGB
    }
  }

  const locale = getLocale()
  setDefaultOptions({ locale })

  return locale
}

export default useLocale
