import { styled, Typography, Grid } from '@mui/material'

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '0.875rem',
}))

export const Row = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}))
