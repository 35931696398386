import { User } from '@firebase/auth'
import { createContext, useContext } from 'react'
import { FirebaseAuth } from 'shared/services'
import { LoadingStatus } from 'shared/types'

export interface AuthContextValue {
  user: User | null
  userId: string | null
  auth: FirebaseAuth | null
  loading: LoadingStatus
  error?: string | null
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  userId: null,
  auth: null,
  loading: LoadingStatus.Idle,
  error: null,
})

export const AuthProvider = AuthContext.Provider

export const useAuthContext = () => useContext(AuthContext)
