import { Button } from '@mui/material'
import { ButtonWrapper } from './DateRangePicker.style'
import { useTranslation } from 'react-i18next'
import { StaticDatePicker } from 'shared/components'

interface DateFilterProps {
  selectedDate: Date | null
  onDateChange: (date: Date) => any
  onClearDate: () => void
  onConfirm: () => void
  clearDateLabel: string
  showTime?: Boolean
}

const DateFilter = ({
  selectedDate,
  onDateChange,
  onClearDate,
  onConfirm,
  clearDateLabel,
}: DateFilterProps) => {
  const { t } = useTranslation()

  return (
    <>
      <StaticDatePicker
        selectedDate={selectedDate}
        onDateChange={onDateChange}
      />
      <ButtonWrapper>
        <Button onClick={onConfirm} fullWidth={false} variant="contained">
          {t('confirm')}
        </Button>
        <Button onClick={onClearDate} fullWidth={false} variant="text">
          {clearDateLabel}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default DateFilter
