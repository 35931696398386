import { Grid } from '@mui/material'
import { Subtitle, Wrapper, Title } from './DetailsHeader.styles'

interface HeaderProps {
  title: string
  subtitle?: string
  hint?: string | null
}

const DetailsHeader = ({ title, subtitle = '', hint = '' }: HeaderProps) => (
  <Wrapper item container alignItems="flex-start" direction="column">
    <Grid item>
      <Title variant="h4">{title}</Title>
    </Grid>
    <Grid item>
      <Subtitle variant="h5">
        <b>{subtitle}</b>
      </Subtitle>
    </Grid>
    {hint && (
      <Grid item>
        <Subtitle variant="h6">{hint}</Subtitle>
      </Grid>
    )}
  </Wrapper>
)

export default DetailsHeader
