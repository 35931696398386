import { InputLabel as BaseInputLabel, alpha, styled } from '@mui/material';
import { TextField as BaseTextField, TextFieldProps } from '..';

const PREFIX = 'TextAreaField';
export const classes = {
  root: `${PREFIX}-root`,
};

export const InputLabel = styled(BaseInputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const TextField = styled(BaseTextField)<TextFieldProps>(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2, 1, 2, 3),
    backgroundColor: alpha(theme.palette.common.black, 0.13),
    fontSize: 12,
    boxShadow: `inset 0px 4px 4px ${alpha(theme.palette.common.black, 0.13)}`,
    borderRadius: 3,
  },
}));
