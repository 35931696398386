import { Paper, styled } from '@mui/material'
import { ReactNode } from 'react'

type PropsWithChildren<P = unknown> = P & { children: ReactNode }

export const Wrapper = styled(Paper)(({ theme }) => ({
  boxSizing: 'border-box',
  paddingBottom: theme.spacing(4),
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  padding: theme.spacing(2),
}))

const ContentWrapper = ({ children }: PropsWithChildren) => (
  <Wrapper>{children}</Wrapper>
)

export default ContentWrapper
