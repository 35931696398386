export enum Errors {
  InvalidEmail = 'auth/invalid-email',
  UserNotFound = 'auth/user-not-found',
  NetworkRequestFailed = 'auth/network-request-failed',
  ExpiredActionCode = 'auth/expired-action-code',
  InvalidActionCode = 'auth/invalid-action-code',
  UserDisabled = 'auth/user-disabled',
  WeekPassword = 'auth/weak-password',
  AuthEmailNotFound = 'auth-email-not-found',
  RequiresRecentLogin = 'auth/requires-recent-login',
}
