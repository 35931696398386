import React, { PropsWithChildren } from 'react'
import { Grid, Typography, Button, ButtonProps } from '@mui/material'
import { Container, ChildrenContainer } from './TablePageContainer.style'

type TablePageContainerProps = PropsWithChildren<{
  title?: string
  action?: Partial<ButtonProps> & {
    title: string
  }
  containerStyle?: React.CSSProperties
}>

const TablePageContainer = ({
  title,
  children,
  action,
  containerStyle,
}: TablePageContainerProps) => (
  <Container style={containerStyle}>
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="flex-start"
      flexWrap="nowrap"
    >
      <Grid item container justifyContent="space-between">
        <Typography variant="h4">{title}</Typography>
        {action && (
          <Button
            onClick={action.onClick}
            fullWidth={false}
            color="primary"
            variant="contained"
            {...action}
          >
            {action.title}
          </Button>
        )}
      </Grid>
      <ChildrenContainer item>{children}</ChildrenContainer>
    </Grid>
  </Container>
)

export default TablePageContainer
