import { Components } from '@mui/material'
import { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

/**
 * This maps Material-UI's link behavior to React Router.
 */
const RouterLink = forwardRef<
  any,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(({ href, ...props }, ref) => <Link ref={ref} to={href} {...props} />)

const SHADOW = '0px 3px 12px 0px rgba(11, 53, 171, 0.16)'
const HOVER_COLOR = '#F1F3FF'
const CONTRAST_TEXT_COLOR = '#FBFCFF'
const DISABLED_BUTTON_COLOR = '#A1A1A1'
const BORDER_RADIUS = '12px'

const overrides: Components = {
  MuiButton: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '14px',
        '&:disabled': {
          color: DISABLED_BUTTON_COLOR,
          borderColor: DISABLED_BUTTON_COLOR,
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      // @ts-ignore
      component: RouterLink,
      underline: 'hover',
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        sx: {
          borderRadius: BORDER_RADIUS,
          padding: '6px',
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: CONTRAST_TEXT_COLOR,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS,
        boxShadow: SHADOW,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: HOVER_COLOR,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: CONTRAST_TEXT_COLOR,
        borderRadius: BORDER_RADIUS,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        borderRadius: BORDER_RADIUS,
        backgroundColor: CONTRAST_TEXT_COLOR,
        boxShadow: SHADOW,
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
        },
      },
    },
  },

  MuiTableContainer: {
    styleOverrides: {
      root: {
        background: CONTRAST_TEXT_COLOR,
        borderRadius: BORDER_RADIUS,
        boxShadow: SHADOW,
      },
    },
  },
}

export default overrides
