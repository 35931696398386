import { IconButton, Select, MenuItem, FormControl } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { Wrapper, Typography, Label } from './Pagination.style'
import { SelectChangeEvent } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface PaginationProps {
  page: number
  pageCount: number
  onPage: number
  changePageSize: (onPage: number) => void
  goNextPage: () => void
  goPrevPage: () => void
  goFirstPage: () => void
  canGoPrevPage: boolean
  canGoNextPage: boolean
  pageSizeOptions: number[]
  visibleLabel?: boolean
}

const Pagination = ({
  page,
  pageCount,
  onPage,
  changePageSize,
  goNextPage,
  goPrevPage,
  goFirstPage,
  canGoNextPage,
  canGoPrevPage,
  pageSizeOptions,
  visibleLabel = true,
}: PaginationProps) => {
  const { t } = useTranslation()
  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    changePageSize(e.target.value as number)
  }
  return (
    <Wrapper item xs={12}>
      {visibleLabel && <Label>{t('rowsPerPage')}</Label>}
      <FormControl size="small">
        <Select value={onPage} onChange={handleSelectChange}>
          {pageSizeOptions.map(option => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton size="small" onClick={goFirstPage} disabled={!canGoPrevPage}>
        <FirstPageIcon />
      </IconButton>
      <IconButton size="small" onClick={goPrevPage} disabled={!canGoPrevPage}>
        <NavigateBeforeIcon />
      </IconButton>
      <Typography>
        {page} / {pageCount}
      </Typography>
      <IconButton size="small" onClick={goNextPage} disabled={!canGoNextPage}>
        <NavigateNextIcon />
      </IconButton>
    </Wrapper>
  )
}

export default Pagination
