import {
  List,
  ListProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Radio,
} from '@mui/material'
import { Option } from 'shared/types'

interface SingleselectListProps<T, K> extends ListProps {
  options?: Option<T, K>[]
  selectedValue: K
  onItemClick: (value: K) => any
  isLoading?: boolean
}

const StyledList = styled(List)({
  width: '100%',
})

const SingleselectList = <T, K>({
  selectedValue,
  onItemClick,
  options = [],
  isLoading = false,
  ...props
}: SingleselectListProps<T, K>) => (
  <StyledList {...props}>
    {options.map(({ title, value }) => {
      const isItemActive = selectedValue === value
      return (
        <ListItem key={`singleselect-list-item-${value}`} disablePadding>
          <ListItemButton
            role="radio"
            onClick={() => onItemClick(value)}
            dense
            disabled={isLoading}
          >
            <ListItemIcon>
              <Radio
                edge="start"
                checked={isItemActive}
                tabIndex={-1}
                disableRipple
                disabled={isLoading}
              />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </ListItem>
      )
    })}
  </StyledList>
)

export default SingleselectList
