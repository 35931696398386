import { GridRowId } from '@mui/x-data-grid'
import { useState, useRef, useEffect } from 'react'
import { PageInfo } from 'shared/types'

const INITIAL_PAGE_SIZE = 10
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 20, 50]

export const useDataGridPagination = (
  loading: boolean,
  totalCount: number | undefined,
  pageInfo: PageInfo | undefined
) => {
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
  const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({})
  const [page, setPage] = useState(0)
  const cursor = mapPageToNextCursor.current[page - 1]
    ? mapPageToNextCursor.current[page - 1].toString()
    : ''

  useEffect(() => {
    if (!loading && pageInfo?.endCursor) {
      mapPageToNextCursor.current[page] = pageInfo?.endCursor
    }
  }, [page, loading, pageInfo?.endCursor])

  const [rowCountState, setRowCountState] = useState(totalCount || 0)

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      totalCount !== undefined ? totalCount : prevRowCountState
    )
  }, [totalCount, setRowCountState])

  const handlePageChange = (newPage: number) => {
    if (newPage === 0 || mapPageToNextCursor.current[newPage - 1]) {
      setPage(newPage)
    }
  }

  return {
    pageSize,
    setPageSize,
    page,
    setPage,
    cursor,
    rowCountState,
    handlePageChange,
  }
}
