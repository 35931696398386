import { HTMLAttributes } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { Container } from './PageLoader.style'

export interface PageLoaderProps extends HTMLAttributes<HTMLDivElement> {
  label?: string
  size?: number
  progressColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'
  fullHeight?: boolean
}

const PageLoader = ({ label, size = 100, progressColor = "primary", ...props }: PageLoaderProps) => (
  <Container {...props}>
    <CircularProgress size={size} color={progressColor} />
    <Typography variant="subtitle1" color="textPrimary">
      {label}
    </Typography>
  </Container>
)

export default PageLoader
