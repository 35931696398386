import { useAuthContext } from 'shared/components/AuthContext'
import PersonIcon from '@mui/icons-material/Person'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, IconWrapper, Menu, MenuItem, Wrapper } from './UserMenu.style'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isGlobalworth, paths } from 'config'
import { Logout as LogoutIcon, Padlock } from 'shared/assets'
import { PowerSettingsNew } from '@mui/icons-material'
import { useAuth, useIsMobile } from 'shared/hooks'
import { useHistory } from 'react-router'
import { ConfirmDialog } from 'shared/components'

interface Option {
  label: string
  Icon?: React.ReactNode
  onClick: () => void
}

const UserMenu = () => {
  const { user } = useAuthContext()
  const { signOut } = useAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const isMobile = useIsMobile()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const options: Option[] = [
    {
      label: t('changePassword'),
      Icon: isGlobalworth ? <Padlock /> : <LockOpenIcon fontSize="small" />,
      onClick: () => {
        history.push(paths.changePassword)
        setAnchorEl(null)
      },
    },
    {
      label: t('logout'),
      Icon: isGlobalworth ? (
        <LogoutIcon />
      ) : (
        <PowerSettingsNew fontSize="small" />
      ),
      onClick: () => {
        setAnchorEl(null)
        setIsModalVisible(true)
      },
    },
    {
      label: t('deleteAccount'),
      Icon: <DeleteOutlineIcon fontSize="small" />,
      onClick: () => {
        history.push(paths.deleteAccount)
        setAnchorEl(null)
      },
    },
  ]
  return (
    <>
      <ConfirmDialog
        message={t('confirmLogout')}
        onCancel={() => setIsModalVisible(false)}
        open={isModalVisible}
        onConfirm={signOut}
      />
      <Wrapper>
        <Menu
          id="user-menu"
          MenuListProps={{
            'aria-labelledby': 'user-menu-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isMobile && <MenuItem disabled>{user?.email}</MenuItem>}
          {options.map(({ label, Icon, onClick }, idx) => (
            <MenuItem key={idx} disableRipple onClick={onClick}>
              {Icon && <IconWrapper>{Icon}</IconWrapper>}
              {label}
            </MenuItem>
          ))}
        </Menu>
        <Button
          variant="text"
          startIcon={<PersonIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          fullWidth={false}
          onClick={handleClick}
          disableElevation
        >
          {!isMobile && user?.email}
        </Button>
      </Wrapper>
    </>
  )
}

export default UserMenu
