import { Button, Menu, MenuItem } from './LanguageSelect.style'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LanguageIcon from '@mui/icons-material/Language'
import { useState } from 'react'
import i18next from 'i18next'
import { languages } from 'config/i18n'

const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSelect = (languageCode: string) => {
    i18next.changeLanguage(languageCode)
  }

  return (
    <>
      <Button
        id="language-select-button"
        aria-controls={open ? 'language-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="info"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<LanguageIcon fontSize="medium" />}
        fullWidth={false}
      >
        {i18next.language.split('-')[0].toUpperCase()}
      </Button>
      <Menu
        id="language-select-menu"
        MenuListProps={{
          'aria-labelledby': 'language-select-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map(({ code, icon, name }) => (
          <MenuItem key={code} onClick={() => onSelect(code)} disableRipple>
            {icon && (
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${icon}.png`}
                alt={`Flag of ${name}`}
              />
            )}
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelect
