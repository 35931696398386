import { Divider, List, styled } from '@mui/material'
import { BaseNestedMultiselectListProps } from '.'
import NestedMultiselectListItem from './NestedMultiselectListItem'
import { Fragment } from 'react'

const NestedMultiselectList = <T, K>({
  selectedValues,
  onItemClick,
  options,
  editingDisabled = false,
  ...props
}: BaseNestedMultiselectListProps<T, K>) => {
  return (
    <StyledList disablePadding {...props}>
      {options.map(({ title, value: parentValue, subItems, subtitle }) => {
        const selectedValue = selectedValues.find(
          it => it.value === parentValue
        )
        return (
          <Fragment key={`multiselect-list-${parentValue}`}>
            <NestedMultiselectListItem
              disabled={editingDisabled}
              selectedValue={selectedValue || null}
              options={subItems}
              title={title as unknown as string}
              onClick={() =>
                onItemClick(
                  parentValue,
                  subItems.map(item => item.value)
                )
              }
              onItemClick={(value: K) => {
                onItemClick(parentValue, value)
              }}
              key={`multiselect-list-item-${parentValue}`}
              subtitle={subtitle}
            />
            {options.at(-1)?.value !== parentValue && <Divider />}
          </Fragment>
        )
      })}
    </StyledList>
  )
}

const StyledList = styled(List)({
  width: '100%',
})

export default NestedMultiselectList
