import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  Divider,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { NestedMultiselectListOption } from 'shared/components'

interface LocksListProps {
  locks: NestedMultiselectListOption<string, string>[]
}

const LocksList = ({ locks }: LocksListProps) => {
  return (
    <List>
      {locks.map(lock => (
        <Fragment key={`Lock-${lock.value}`}>
          <LocksListItem lock={lock} key={lock.value} />
          {locks.at(-1)?.value !== lock.value && <Divider />}
        </Fragment>
      ))}
    </List>
  )
}

const LocksListItem = ({
  lock,
}: {
  lock: NestedMultiselectListOption<string, string>
}) => {
  const [open, setOpen] = useState(false)
  const { title, subtitle, subItems } = lock

  return (
    <>
      <ListItemButton onClick={() => setOpen(it => !it)}>
        <ListItemText primary={title} secondary={subtitle} />
        {subItems.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      {subItems.length > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map(({ value, title, subtitle }) => (
              <ListItemButton sx={{ pl: 4 }} key={value}>
                <ListItemText primary={title} secondary={subtitle} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  )
}

export default LocksList
