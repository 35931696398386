import { AppBarProps, Toolbar, Hidden } from '@mui/material'
import { ChevronLeft, Menu } from '@mui/icons-material'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  StyledTopbar,
  IconButton,
  ButtonWrapper,
  ButtonText,
  Button,
} from './Topbar.style'
import LanguageSelect from './LanguageSelect'
import UserMenu from './UserMenu'

export interface TopbarProps extends Partial<AppBarProps> {
  hidden?: boolean
  backTo?: {
    title: string
    href: string
  }
  onMenuClick?: () => void
}

const Topbar = ({ hidden, backTo, onMenuClick, ...props }: TopbarProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  if (hidden) return null

  return (
    <StyledTopbar position="sticky" color="primary" {...props}>
      <Toolbar>
        <Hidden xlUp>
          <IconButton size="large" edge="start" onClick={onMenuClick}>
            <Menu />
          </IconButton>
        </Hidden>
        <ButtonWrapper>
          {backTo && (
            <Button
              onClick={() => history.push(backTo.href)}
              fullWidth={false}
              variant="text"
              color="primary"
            >
              <ChevronLeft />
              <ButtonText>{t(backTo.title)}</ButtonText>
            </Button>
          )}
        </ButtonWrapper>
        <UserMenu />
        <LanguageSelect />
      </Toolbar>
    </StyledTopbar>
  )
}

export default Topbar
