import { PaletteOptions } from '@mui/material'
import createPalette from '@mui/material/styles/createPalette'

const palette: PaletteOptions = {
  primary: {
    light: '#C72035',
    main: '#C72035',
    dark: '#C72035',
    contrastText: '#fff',
  },
  secondary: {
    main: '#C72035',
  },
  text: {
    primary: '#676767',
  },
  info: {
    main: '#fff',
    light: '#D2D7E1',
    dark: '#757575',
  },
  grey: {
    300: '#B3B3B3',
    400: '#c6c6c6',
    500: '#c4c4c4',
  },
}

export default createPalette(palette)
