import { isGlobalworth, paths } from 'config'
import { Loadable } from 'shared/components'
import {
  LocalParking,
  MeetingRoom,
  TableRestaurant,
  Event,
} from '@mui/icons-material'
import { ModuleRoute } from 'shared/types'
import { Board, Booking, Car, Desk } from 'shared/assets'
import { Locker } from 'shared/assets/globalworth'

const routes: ModuleRoute[] = [
  {
    path: paths.parkingSpots,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Booking : Event,
      label: 'nav.booking',
    },
    component: Loadable({
      component: () => import('./pages/ParkingSpots'),
    }),
    subroutes: [
      {
        path: '/booking/parking-spots',
        exact: true,
        linkSettings: {
          icon: isGlobalworth ? Car : LocalParking,
          label: 'nav.parkingSpots',
        },
        component: Loadable({
          component: () => import('./pages/ParkingSpots'),
        }),
      },
      {
        path: '/booking/conference-rooms',
        exact: true,
        linkSettings: {
          icon: isGlobalworth ? Board : MeetingRoom,
          label: 'nav.conferenceRooms',
        },
        component: Loadable({
          component: () => import('./pages/ConferenceRooms'),
        }),
      },
      {
        path: '/booking/desks',
        exact: true,
        linkSettings: {
          icon: isGlobalworth ? Desk : TableRestaurant,
          label: 'nav.desks',
        },
        component: Loadable({
          component: () => import('./pages/Desks'),
        }),
      },
      ...(isGlobalworth
        ? [
            {
              path: '/booking/lockers',
              exact: true,
              linkSettings: {
                icon: Locker,
                label: 'nav.lockers',
              },
              component: Loadable({
                component: () => import('./pages/Lockers'),
              }),
            },
          ]
        : []),
    ],
  },
]

export default routes
