import { Button } from '@mui/material'
import { ButtonWrapper } from './DateTimeRangePicker.style'
import { useTranslation } from 'react-i18next'
import { StaticDateTimePicker } from 'shared/components'

interface DateTimeFIlterProps {
  selectedDate: Date | null
  onDateChange: (date: Date | null) => any
  onClearDate: () => void
  onConfirm: () => void
  clearDateLabel: string
  showTime?: Boolean
}

const DateTimeFilter = ({
  selectedDate,
  onDateChange,
  onClearDate,
  onConfirm,
  clearDateLabel,
}: DateTimeFIlterProps) => {
  const { t } = useTranslation()

  return (
    <>
      <StaticDateTimePicker
        selectedDate={selectedDate}
        onDateChange={onDateChange}
      />
      <ButtonWrapper>
        <Button onClick={onClearDate} fullWidth={false} variant="text">
          {clearDateLabel}
        </Button>
        <Button onClick={onConfirm} fullWidth={false} variant="contained">
          {t('confirm')}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default DateTimeFilter
