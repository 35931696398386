import { styled, Grid } from '@mui/material'

export const Container = styled(Grid)({
  flexDirection: 'column',
  height: '100%',
  flexWrap: 'nowrap',
})

export const SearchWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(0.5, 0, 1),
}))

export const PaginationWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 0, 0),
}))

export const BottomButtonWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 0, 0),
}))

export const ListWrapper = styled(Grid)({
  flex: 1,
  overflowY: 'scroll',
})
