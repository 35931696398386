import { paths } from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.changePassword,
    exact: true,
    admin: false,
    component: Loadable({
      component: () => import('./pages/ChangePassword'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
]

export default routes
