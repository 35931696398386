import { useState } from 'react'

interface DateRangeProps {
  fromDate: Date | null
  toDate: Date | null
}
const TIME_SLOT_MINUTES_INTERVAL = 30

const orderDates = (
  from: Date,
  to: Date,
  direction: 'forward' | 'backward'
) => {
  if (direction === 'forward' && from >= to) {
    let nextSlot = new Date(from)
    nextSlot.setMinutes(nextSlot.getMinutes() + TIME_SLOT_MINUTES_INTERVAL)
    return { from, to: nextSlot }
  } else if (direction === 'backward' && from >= to) {
    let prevSlot = new Date(to)
    prevSlot.setMinutes(prevSlot.getMinutes() - TIME_SLOT_MINUTES_INTERVAL)
    return { from: prevSlot, to }
  }
  return { from, to }
}

const useDateRange = ({ fromDate, toDate }: DateRangeProps) => {
  const [currentFromDate, setCurrentFromDate] = useState<Date | null>(fromDate)
  const [currentToDate, setCurrentToDate] = useState<Date | null>(toDate)

  const handleClearDates = () => {
    setCurrentToDate(null)
    setCurrentFromDate(null)
  }

  const handleFromDateChange = (date: Date | null) => {
    if (date && !currentToDate) {
      setCurrentFromDate(date)
      const nextSlot = new Date(date)
      nextSlot.setMinutes(nextSlot.getMinutes() + TIME_SLOT_MINUTES_INTERVAL)
      setCurrentToDate(nextSlot)
      return
    }
    if (date && currentToDate) {
      const { from, to } = orderDates(date, currentToDate, 'forward')
      setCurrentFromDate(from)
      setCurrentToDate(to)
      return
    }
    setCurrentFromDate(date)
  }

  const handleToDateChange = (date: Date | null) => {
    if (date && !currentFromDate) {
      setCurrentToDate(date)
      const prevSlot = new Date(date)
      prevSlot.setMinutes(prevSlot.getMinutes() - TIME_SLOT_MINUTES_INTERVAL)
      setCurrentFromDate(prevSlot)
      return
    }
    if (date && currentFromDate) {
      const { from, to } = orderDates(currentFromDate, date, 'backward')
      setCurrentFromDate(from)
      setCurrentToDate(to)
      return
    }
    setCurrentToDate(date)
  }
  const resetPickers = () => {
    setCurrentFromDate(fromDate)
    setCurrentToDate(toDate)
  }
  return {
    currentFromDate,
    currentToDate,
    handleFromDateChange,
    handleToDateChange,
    handleClearDates,
    resetPickers,
  }
}
export default useDateRange
