import { useState, MouseEvent } from 'react'

export const usePopover = (): [
  HTMLButtonElement | null,
  boolean,
  (e: MouseEvent<HTMLButtonElement>) => void,
  () => void
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = () => {
    setAnchorEl(null)
  }

  const isPopoverOpen = Boolean(anchorEl)

  return [anchorEl, isPopoverOpen, openPopover, closePopover]
}
