import { Grid, Typography, styled } from '@mui/material'

export const Wrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}))

export const Title = styled(Typography)({
  fontSize: '2.25rem',
  lineHeight: '2.625rem',
})

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}))
