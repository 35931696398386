import { PeopleOutline } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { Profiles } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.profiles,
    admin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Profiles : PeopleOutline,
      label: 'nav.profiles',
    },
    component: Loadable({
      component: () => import('./pages/Profiles'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.addProfile,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddProfile'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.profilesList',
          href: paths.profiles,
        },
      },
    },
  },
  {
    path: paths.profile,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Profile'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.profilesList',
          href: paths.profiles,
        },
      },
    },
  },
]

export default routes
