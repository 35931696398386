import { gql } from '@apollo/client'

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferencesMutation($input: UpdateUserPreferencesInput!) {
    updateUserPreferences(input: $input) {
      viewer {
        id
      }
    }
  }
`
