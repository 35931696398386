import { useTranslation } from 'react-i18next'
import { Option, ExtendedOption, User } from 'shared/types'

export const useMapUserToOption = () => {
  const { t } = useTranslation()
  const mapUserToOption = ({
    email: title,
    id: value,
    name: subtitle,
  }: User): Option<string, string> => {
    if (!title && !subtitle)
      return {
        title: t('anonymousUser'),
        value,
      }
    return {
      title: title || subtitle || '',
      subtitle: subtitle || undefined,
      value,
    }
  }

  return mapUserToOption
}

interface UserWithDetails extends User {
  disabled?: boolean
}

export const useMapUserToExtendedOption = () => {
  const { t } = useTranslation()
  const mapUserToOption = ({
    email: title,
    id: value,
    name: subtitle,
    disabled,
  }: UserWithDetails): ExtendedOption<string, string> => {
    if (!title && !subtitle)
      return {
        title: t('anonymousUser'),
        value,
      }
    return {
      title: title || subtitle || '',
      subtitle: subtitle || undefined,
      value,
      disabled,
    }
  }

  return mapUserToOption
}
