import { Grid, TextField } from '@mui/material'
import { StaticDatePicker } from '@mui/lab'
import { DatePickerWrapper } from './StaticDatePicker.style'

interface StaticDateTimePickerProps {
  selectedDate: Date | null
  onDateChange: (date: Date) => any
}

const DATE_FORMAT_PATTERN = 'dd.MM.yyyy'

const StaticDateTimePicker = ({
  selectedDate,
  onDateChange,
}: StaticDateTimePickerProps) => {
  const handleDateChange = (dateInput: Date | null) => {
    if (!dateInput) return
    onDateChange(dateInput)
  }

  return (
    <Grid container flexDirection="column" marginBottom={2}>
      <DatePickerWrapper container>
        <StaticDatePicker
          showToolbar={false}
          inputFormat={DATE_FORMAT_PATTERN}
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={params => <TextField {...params} />}
        />
      </DatePickerWrapper>
    </Grid>
  )
}

export default StaticDateTimePicker
