import { HTMLAttributes } from 'react'
import { styled } from '@mui/material'
import { DRAWER_WIDTH } from './Drawer'
import { isGlobalworth } from 'config'

export const Container = styled('div')({
  display: 'flex',
  minHeight: '100vh',
})

export interface ContentProps extends Partial<HTMLAttributes<HTMLDivElement>> {
  shrink?: boolean
}

export const Content = styled('main', {
  shouldForwardProp: prop => prop !== 'shrink',
})<ContentProps>(({ shrink, ...props }) => ({
  flexGrow: 1,
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: shrink ? DRAWER_WIDTH : 0,
  overflowX: 'hidden',
  backgroundImage: isGlobalworth
    ? 'none'
    : 'radial-gradient(109.05% 79.25% at 32.18% 46.15%, rgba(200, 209, 255, 0.41) 0%, #FFF 100%);',
}))
