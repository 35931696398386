import { PropsWithChildren } from "react";
import { useCurrentUser } from "shared/data";
import { AuthContextValue } from "./AuthContext";
import { LoadingStatus } from "shared/types";
import PageLoader from "./PageLoader";

interface AuthLoaderProps {
    user: AuthContextValue;
}

const AuthLoader = ({
    children,
    ...props
}: PropsWithChildren<AuthLoaderProps>) => {
    const { loading: cloudUserLoading } = useCurrentUser()
    const firebaseUserLoading = [LoadingStatus.Idle, LoadingStatus.Pending].includes(props.user.loading);
    const loading = cloudUserLoading || firebaseUserLoading

    return (
        <>
            {!loading && children}
            {loading && <PageLoader progressColor="secondary" fullHeight={true} />}
        </>
    )

}
export default AuthLoader
